import { DomainTelNumbersQuery } from '@/client/generated/graphql';

export enum OpenModalType {
  Off,
  RefuseBought,
  AbortRefuse,
  Bought,
}

export interface INumberDiscountProps {
  discount: number;
  isAbsolute: boolean;
}

export type DomainNumber = Exclude<
  DomainTelNumbersQuery['getNumbers'][number],
  { __typename: 'FmcNumberModel' }
>;

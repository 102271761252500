/** This type represents EXIF file data retrieved with 'exif-js'.
 * Data is not guaranteed to have this shape, type was created through observation.
 */
export type ExifData = {
  lastModified: number;
  lastModifiedDate: Date;
};

/** Standard exif date string in format YYYY:MM:DD HH:MM:SS */
type DateTimeOriginal = string;

const hasDateTimeOriginal = (
  obj: unknown
): obj is { exifdata: { DateTimeOriginal: DateTimeOriginal } } => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const exifdata = (obj as Record<string, unknown>).exifdata;
  if (typeof exifdata !== 'object' || exifdata === null) {
    return false;
  }

  const dateTimeOriginal = (exifdata as Record<string, unknown>).DateTimeOriginal;
  if (typeof dateTimeOriginal !== 'string') {
    return false;
  }

  return true;
};

export const getLastModifiedDateFromExifJsObject = (obj: unknown) => {
  if (!hasDateTimeOriginal(obj)) {
    return null;
  }

  const dateTimeOriginal = obj.exifdata.DateTimeOriginal;
  const match = dateTimeOriginal.match(/(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
  if (!match) {
    return null;
  }

  const date = new Date(
    Number(match[1]),
    Number(match[2]) - 1,
    Number(match[3]),
    Number(match[4]),
    Number(match[5]),
    Number(match[6])
  );

  return date;
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExcelExportIcon, HistoryIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import { addSeconds, format, subSeconds } from 'date-fns';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_BLACK_LIST_QUERY,
  HAS_HISTORY_CALLS_QUERY,
  HISTORY_QUERY,
  OWN_HISTORY_QUERY,
} from '@/client/queries';
import { getStorageItem } from '@components/storage/storage';
import QuickSearch from '@components/QuickSearch';
import Tag from '@components/Tag';
import Button from '@shared/components/Button';
import PeriodSelect from '@/components/PeriodSelect/PeriodSelect';
import {
  FilterButtonIconTypes,
  IButtonFilterProps,
} from '@/components/ButtonFilter/ButtonFilter.interfaces';
import { ButtonFilterGroup } from '@/components/ButtonFilterGroup/ButtonFilterGroup';
import { PeriodValues, useDateOptions } from '@/utils/useDateOptions';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { findWeekDay, formatDate, timeToSeconds } from '@components/utils';
import LazyTable, { IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import {
  CallDirection,
  HistoryModel,
  HistoryRowModel,
  HistoryTotalModel,
  Role,
} from '@/client/generated/graphql';
import { getRole } from '@/utils';
import WelcomeDialog from '@/components/WelcomePage/WelcomeDialog';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  callTypes,
  HistoryStatus,
  IHistoryList,
  IHistoryPost,
  IHistoryState,
} from '../HistoryPage.interfaces';
import {
  DEFAULT_TIME_FROM,
  DEFAULT_TIME_TO,
  formatTime,
  getCallType,
  HISTORY_DATE_ROW_HEIGHT,
  HISTORY_DEFAULT_ROW_HEIGHT,
} from '../HistoryPage.constants';
import { useHistoryStyle } from '../HistoryPage.styles';
import {
  ActionsCell,
  CallTypesCell,
  ClientCell,
  DateCell,
  DurationCell,
  EmployeeCell,
  TimeCell,
  ViaCell,
  WaitCell,
  HeaderTimeCell,
} from '../modules';

export const CompanyCalls = () => {
  const [translate] = useTranslation();
  const classes = useHistoryStyle();
  const [searchParams, setSearchParams] = useSearchParams();
  const sSearch = searchParams.get('search');
  const sPhone = searchParams.get('phone');
  const sEmployee = searchParams.get('employee');
  const sDepartment = searchParams.get('department');
  const sFilterName = searchParams.get('filterName');
  const sOther = searchParams.get('other');
  const sDirection = searchParams.get('direction');
  const sMissed = searchParams.get('missed');
  const sFrom = searchParams.get('from');
  const sTo = searchParams.get('to');
  const sFromTime = searchParams.get('fromTime');
  const sToTime = searchParams.get('toTime');
  const sPeriod = searchParams.get('period');
  const sVia = searchParams.get('via');
  const dataLine = `${sSearch}${sPhone}${sVia}${sEmployee}${sDepartment}${sOther}${sDirection}${sMissed}${sFrom}${sTo}${sPeriod}${sFromTime}${sToTime}`;
  const [hasSearchParams, setHasSearchParams] = useState(false);
  const { data: dataAuthStateQuery } = useQuery<{ role?: string }>(AUTHENTICATION_STATE_QUERY);
  const role = getRole(dataAuthStateQuery?.role);
  const { data: blacklistData } = useQuery(GET_BLACK_LIST_QUERY, {
    variables: { data: { search: null } },
  });

  const blacklist = blacklistData?.getBlackList.rows;
  const isRoleOperator = Role.Operator === role;

  const formMethods = useForm({
    defaultValues: {
      search: sSearch,
    },
  });

  const columns: IColumn<IHistoryList>[] = [
    {
      id: 'call',
      label: '',
      minWidth: '1em',
      width: '1em',
      verticalAlign: 'center',
      Renderer: CallTypesCell,
    },
    {
      id: 'employee',
      label: 'EMPLOYEE',
      verticalAlign: 'center',
      Renderer: EmployeeCell,
    },
    {
      id: 'client',
      label: 'CLIENT',
      verticalAlign: 'center',
      Renderer: ClientCell,
    },
    {
      id: 'via',
      label: 'VIA',
      verticalAlign: 'center',
      Renderer: ViaCell,
    },
    {
      id: 'date',
      label: 'DATE',
      width: '5em',
      minWidth: '4em',
      verticalAlign: 'center',
      Renderer: DateCell,
    },
    {
      id: 'time',
      label: 'TIME',
      width: '5em',
      minWidth: '4em',
      verticalAlign: 'center',
      Renderer: TimeCell,
      HeaderRenderer: HeaderTimeCell,
    },
    {
      id: 'wait',
      label: 'WAIT',
      width: '5em',
      minWidth: '4em',
      verticalAlign: 'center',
      Renderer: WaitCell,
    },
    {
      id: 'duration',
      label: 'DURATION',
      width: '8em',
      minWidth: '8em',
      verticalAlign: 'center',
      Renderer: DurationCell,
    },
    {
      id: 'action',
      label: '',
      width: '100%',
      minWidth: '8em',
      align: 'left',
      verticalAlign: 'center',
      className: classes.cellAction,
      Renderer: ActionsCell,
    },
  ];

  const periodData = useDateOptions(null);
  const currentDay = periodData[PeriodValues.Today].to; // new Date();
  const startDay = periodData[PeriodValues.Today].from; // startOfDay(currentDay)

  const periodRangeDates = {
    from: sFrom ? new Date(sFrom) : startDay,
    to: sTo ? new Date(sTo) : currentDay,
  };
  const timeRange = {
    fromTime: sFromTime || undefined,
    toTime: sToTime || undefined,
  };
  const [queryData, setQueryData] = useState<IHistoryPost>({
    from: sFrom || startDay.toISOString(),
    to: sTo || currentDay.toISOString(),
    fromTime: sFromTime || DEFAULT_TIME_FROM,
    toTime: sToTime || DEFAULT_TIME_TO,
    search: sSearch || null,
    phone: sPhone || null,
    via: sVia || null,
    employee: Number(sEmployee) || null,
    department: Number(sDepartment) || null,
    other: sOther || null,
    direction: (sDirection as CallDirection) || null,
    missed: sMissed ? sMissed === 'true' : null,
    totals: true,
    rows: true,
    offset: null,
    limit: 20,
    status: null,
  });

  const historyQuery = isRoleOperator ? OWN_HISTORY_QUERY : HISTORY_QUERY;
  const historyKey = isRoleOperator ? 'getOwnHistory' : 'getHistory';

  const { data: historyData, refetch: getHistoryRaw } = useQuery(historyQuery, {
    variables: {
      conditions: queryData,
    },
    fetchPolicy: 'cache-first',
    skip: !role,
  });

  // TODO handle lazy query error
  const [getHistoryRows, { data: rowsDataFromQuery }] = useLazyQuery(historyQuery, {
    fetchPolicy: 'network-only',
  });
  /**
   * This timeout ref is used to prevent data race between
   * two getHistory functions. When we change search params,
   * our LazyTable loadMore function is fired alongside another
   * getHistory function, which sits in useEffect. By this ref and
   * a little delay we are able to eliminate unnecessary call with
   * loadMore function.
   */
  const refGetHistoryRowsTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    data: isHistoryData,
    loading: loadingHistoryData,
    called: calledHistoryData,
  } = useQuery(HAS_HISTORY_CALLS_QUERY);
  const hasHistoryCalls = isHistoryData?.hasHistoryCalls;

  const history = (historyData as any)?.[historyKey] as HistoryModel | undefined;
  const rows = (rowsDataFromQuery as any)?.[historyKey]?.rows;
  const [state, setState] = useState<IHistoryState>({
    dataLine,
    history,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [totals, setTotals] = useState<IButtonFilterProps[]>([]);
  const [tableRows, setTableRows] = useState<IHistoryList[]>([]);

  const sortNumber = (dir: CallDirection | null | undefined, isMissed: boolean) => {
    if (dir === CallDirection.In && !isMissed) {
      return 1;
    }
    if (dir === CallDirection.In && isMissed) {
      return 2;
    }
    if (dir === CallDirection.Out && !isMissed) {
      return 3;
    }
    if (dir === CallDirection.Out && isMissed) {
      return 4;
    }
    return 0;
  };

  const addBlacklistInfo = useCallback(
    (element: { client: string }) => {
      const blacklistItem = blacklist?.find((el) => el.phone === element.client);
      return {
        isInBlackList: Boolean(blacklistItem),
        blackListComment: blacklistItem?.comment ?? null,
      };
    },
    [blacklist]
  );

  const rowsData: (d?: HistoryRowModel[] | null) => IHistoryList[] = useCallback(
    (d) => {
      if (!d) {
        return [];
      }

      return d.map((item) => {
        const start = new Date(item.date);
        const itemDay = start.getDay();
        const week = findWeekDay(itemDay);
        const { day, month, monthShort } = formatDate(start);
        const blacklistInfo = addBlacklistInfo(item);

        return {
          id: item.id,
          uuid: item.uuid,
          call: callTypes[getCallType(item.direction, item.status !== HistoryStatus.Success)],
          employee: item.employee,
          employeeId: item.employeeId,
          departmentId: item.departmentId,
          other: item.other,
          via: item.via,
          client: item.client,
          start,
          date: {
            week,
            month,
            day,
            monthShort,
          },
          time: format(new Date(item.date), formatTime),
          wait: item.provision,
          duration: item.duration,
          record: item.record,
          fax: item.fax,
          transcription: item.transcription,
          ...blacklistInfo,
        };
      });
    },
    [addBlacklistInfo]
  );

  useEffect(() => {
    setHasSearchParams(!searchParams.keys().next().done);
  }, [searchParams, setHasSearchParams]);

  useEffect(() => {
    if (dataLine !== state.dataLine || forceUpdate) {
      setTableLoading(true);
      const debounceData = setTimeout(() => {
        const miss = sMissed ? sMissed === 'true' : null;
        setState((prevState) => ({
          history: { totals: prevState.history?.totals || [], rows: [] },
          dataLine,
        }));
        setQueryData((prevState) => ({
          ...prevState,
          search: sSearch,
          phone: sPhone,
          via: sVia,
          employee: Number(sEmployee) || null,
          department: Number(sDepartment) || null,
          other: sOther || null,
          direction: sDirection as CallDirection,
          missed: miss,
          from: sFrom || startDay.toISOString(),
          to: sTo || currentDay.toISOString(),
          fromTime: sFromTime || DEFAULT_TIME_FROM,
          toTime: sToTime || DEFAULT_TIME_TO,
          totals: true,
          offset: null,
        }));
        setForceUpdate(false);
      }, 500);

      return () => {
        clearTimeout(debounceData);
      };
    }
    return undefined;
  }, [
    sSearch,
    sPhone,
    sVia,
    sEmployee,
    sDepartment,
    sOther,
    sDirection,
    sFrom,
    sTo,
    sMissed,
    sPeriod,
    state,
    dataLine,
    currentDay,
    startDay,
    forceUpdate,
    sFromTime,
    sToTime,
  ]);

  useEffect(() => {
    if (!role) {
      return;
    }

    if (refGetHistoryRowsTimeout.current !== null) {
      clearTimeout(refGetHistoryRowsTimeout.current);
      refGetHistoryRowsTimeout.current = null;
    }

    getHistoryRaw({ conditions: queryData }).then((response) => {
      const { data } = response;
      setTableLoading(false);
      setState((prevState) => ({
        ...prevState,
        history: (data as any)?.[historyKey] || { totals: [], rows: [] },
      }));
    });
  }, [queryData, getHistoryRaw, role, historyKey]);

  useEffect(() => {
    if (state.history) {
      if (state.history.rows) {
        setTableRows(rowsData(state.history.rows));
      } else {
        setTableRows([]);
      }

      if (state.history.totals !== null) {
        const filterData = (d?: HistoryTotalModel[]) => {
          const result: IButtonFilterProps[] = [];
          const titleText = (dir: CallDirection | null | undefined, isMissed: boolean) => {
            if (dir === null) {
              return translate('FILTER_ALL');
            }
            if (dir === CallDirection.In && !isMissed) {
              return translate('FILTER_INC');
            }
            if (dir === CallDirection.In && isMissed) {
              return translate('FILTER_INC_MISSED');
            }
            if (dir === CallDirection.Out && !isMissed) {
              return translate('FILTER_OUT');
            }
            return translate('FILTER_OUT_MISSED');
          };
          if (d) {
            const selMissed = sMissed === 'true' ? '-MISSED' : '';
            const selDirection = sDirection !== 'null' ? sDirection : 'ALL';

            d.forEach((item: HistoryTotalModel) => {
              const idPart = item.status !== HistoryStatus.Success ? '-MISSED' : '';
              if (item.direction !== CallDirection.Internal) {
                const isMissed = item.status !== HistoryStatus.Success;
                const filterNumber = sortNumber(item.direction, isMissed);
                if (result[filterNumber]) {
                  result[filterNumber].count = (result[filterNumber].count || 0) + item.count;
                } else {
                  result[filterNumber] = {
                    title: titleText(item.direction, isMissed),
                    count: item.count,
                    id: item.direction ? item.direction + idPart : 'ALL',
                    icon: item.direction
                      ? FilterButtonIconTypes[getCallType(item.direction, isMissed)]
                      : FilterButtonIconTypes.All,
                    border: !item.direction || (item.direction === CallDirection.In && isMissed),
                    selected:
                      (sDirection === 'null' || sDirection === null
                        ? 'ALL'
                        : selDirection + selMissed) ===
                      (item.direction ? item.direction + idPart : 'ALL'),
                  };
                }
              }
            });
            if (result.length) {
              result[1].count = (result[1].count || 0) + (result[2].count || 0);
              result[3].count = (result[3].count || 0) + (result[4].count || 0);
            }
          }
          return result;
        };

        setTotals(filterData(state.history?.totals));
      }
    }
  }, [state, translate, sDirection, sMissed, rowsData]);

  useEffect(() => {
    const newRows = rowsData(rows);
    setTableRows((prevState) => [...prevState, ...newRows]);
  }, [rows, rowsData]);

  useEffect(() => {
    setTableRows((prevState) => prevState.map((item) => ({ ...item, ...addBlacklistInfo(item) })));
  }, [addBlacklistInfo]);

  const getMoreHistoryItems = useCallback(() => {
    if (!role || refGetHistoryRowsTimeout.current !== null) {
      return;
    }

    refGetHistoryRowsTimeout.current = setTimeout(() => {
      getHistoryRows({
        variables: {
          conditions: {
            ...queryData,
            totals: false,
            offset: tableRows.length,
          },
        },
      }).finally(() => {
        refGetHistoryRowsTimeout.current = null;
      });
    }, 200);
  }, [getHistoryRows, queryData, role, tableRows.length]);

  const hasNextPage = useCallback(() => {
    if (totals.length > 0 && tableRows.length !== 0) {
      const dir = queryData.direction || null;
      const miss = queryData.missed || false;
      const maxCount = totals[sortNumber(dir, miss)].count;
      return (maxCount || 0) > tableRows.length;
    }
    return false;
  }, [totals, tableRows, queryData.direction, queryData.missed]);

  function handlePeriodChange(date: {
    from: Date;
    to: Date;
    period: string;
    fromTime?: string;
    toTime?: string;
  }) {
    const { from, to, period, fromTime, toTime } = date;
    if (fromTime && toTime) {
      searchParams.set('fromTime', fromTime);
      searchParams.set('toTime', toTime);
    } else {
      searchParams.delete('fromTime');
      searchParams.delete('toTime');
    }
    searchParams.set('from', from.toISOString());
    searchParams.set('to', to.toISOString());
    searchParams.set('period', period);
    setSearchParams(searchParams);
    setForceUpdate(true);
  }

  function handleReportClick() {
    const token = getStorageItem('token');
    const {
      from,
      to,
      search,
      phone,
      via,
      employee,
      department,
      other,
      limit,
      direction,
      missed,
      fromTime,
      toTime,
    } = queryData;
    let url = process.env.API_URL;
    if (token && url) {
      url += `/history/report?from=${from}&to=${to}&direction=${direction}&missed=${missed}&totals=false&rows=true&limit=${limit}`;
      if (fromTime && toTime) url += `&fromTime=${fromTime}&toTime=${toTime}`;
      if (search) url += `&search=${search}`;
      if (phone) url += `&phone=${phone}`;
      if (via) url += `&via=${via}`;
      if (employee) url += `&employee=${employee}`;
      if (department) url += `&department=${department}`;
      if (other) url += `&other=${other}`;
      url += `&token=${token}`;
      window.open(url);
    }
  }

  const handleCloseTag = useCallback(
    (sParam: string) => {
      if (sParam === 'employee') {
        searchParams.delete('employee');
        searchParams.delete('department');
        searchParams.delete('phone');
        searchParams.delete('via');
        searchParams.delete('other');
        searchParams.delete('filterName');
        searchParams.delete(sParam);
      } else {
        searchParams.delete(sParam);
        searchParams.delete('filterName');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  function handleFilterClick(id: string | number) {
    let result: { d: string | null; m: boolean | null };
    switch (id) {
      case CallDirection.In:
        result = { d: CallDirection.In, m: null };
        break;
      case 'In-MISSED':
        result = { d: CallDirection.In, m: true };
        break;
      case CallDirection.Out:
        result = { d: CallDirection.Out, m: null };
        break;
      case 'Out-MISSED':
        result = { d: CallDirection.Out, m: true };
        break;
      default:
        result = { d: null, m: null };
        break;
    }
    if (result.d === null) {
      searchParams.delete('direction');
      searchParams.delete('missed');
    } else {
      searchParams.set('direction', result.d);
      if (result.m) {
        searchParams.set('missed', 'true');
      } else {
        searchParams.delete('missed');
      }
    }
    setSearchParams(searchParams);
  }

  const isDayChanged = (index: number) => {
    if (tableRows[index] && tableRows[index - 1]) {
      if (tableRows[index].date.day !== tableRows[index - 1].date.day) {
        return HISTORY_DATE_ROW_HEIGHT;
      }
      return HISTORY_DEFAULT_ROW_HEIGHT;
    }
    return HISTORY_DATE_ROW_HEIGHT;
  };

  const rowClick = (event: React.MouseEvent<Element, MouseEvent>, row: IHistoryList) => {
    if (
      (event.ctrlKey || event.metaKey) &&
      (role === Role.Sysadmin || role === Role.Supporter) &&
      process.env.GRAYLOG_URL
    ) {
      // https://graylog.gravitel.ru/search?q=%22654687878%22&rangetype=absolute&from=2023-12-11T10%3A12%3A46.000Z&to=2023-12-11T10%3A17%3A46.876Z
      window.open(
        `${process.env.GRAYLOG_URL}/search?q="${row.uuid}"&rangetype=absolute&from=${subSeconds(
          row.start,
          60
        ).toISOString()}&to=${addSeconds(
          row.start,
          timeToSeconds(row.duration) + timeToSeconds(row.wait) + 60
        ).toISOString()}`
      );
    }
  };

  const renderSearchLine = () => {
    if (sPhone) {
      return <Tag text={formatPhone(sPhone)} onClose={() => handleCloseTag('phone')} />;
    }
    if (sVia) {
      return <Tag text={formatPhone(sVia)} onClose={() => handleCloseTag('via')} />;
    }
    if (sFilterName) {
      return <Tag text={sFilterName} onClose={() => handleCloseTag('employee')} />;
    }
    return (
      <FormProvider {...formMethods}>
        <QuickSearch parameterName={'search'} />
      </FormProvider>
    );
  };

  const renderBeforeRowContent = (itemIndex: number) => {
    const tableData = tableRows[itemIndex];
    const dateStr = tableData ? `${tableData.date.day} ${translate(tableData.date.month)}` : '';
    if (isDayChanged(itemIndex) === HISTORY_DATE_ROW_HEIGHT) {
      return (
        <Flex alignItems={'center'} className={classes.dateLine}>
          <div className={classes.cellDirection}>
            <Typography color={'tertiary400'} type={'text4'}>
              {translate(tableData.date.week)}
            </Typography>
          </div>
          <div className={classes.cellNumber}>
            <Typography color={'tertiary400'} type={'text4'}>
              {dateStr}
            </Typography>
          </div>
        </Flex>
      );
    }
    return null;
  };

  const renderEmptyContent = () => (
    <div className={classes.emptyBlock}>
      <HistoryIcon className={classes.ClockIcon} />
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('HERE_WILL_BE_CALLS_HISTORY')}
      </Typography>
    </div>
  );

  return (
    <BodyContainer disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        <Breadcrumbs />
        {isRoleOperator ? (
          <div className={classes.headTitle}>
            <Typography color={'tertiary600'} type={'text2'} bold>
              {translate('MY_CALLS')}
            </Typography>
          </div>
        ) : null}
        <div className={classes.head}>
          <div>{totals && <ButtonFilterGroup onClick={handleFilterClick} data={totals} />}</div>
          <div className={classes.headRight}>
            <div className={classes.lineItem}>{renderSearchLine()}</div>
            <div className={classes.lineItem}>
              <PeriodSelect<PeriodValues>
                periodName={sPeriod || 'today'}
                periodList={periodData}
                datePeriod={periodRangeDates}
                time={timeRange}
                onPeriodChange={handlePeriodChange}
                selectTime
              />
            </div>
            {role && role !== Role.Operator && (
              <div className={classes.lineItem}>
                <Button onClick={handleReportClick} clear rounded>
                  <ExcelExportIcon className={classes.xls} />
                </Button>
              </div>
            )}
          </div>
        </div>
        <Flex direction={'column'} fullWidth className={classes.body}>
          <LazyTable<IHistoryList>
            columns={columns}
            data={tableRows}
            rowLoading={tableLoading || loadingHistoryData}
            loadMoreRows={getMoreHistoryItems}
            hasNextPage={hasNextPage}
            rowHeight={HISTORY_DEFAULT_ROW_HEIGHT}
            emptyDataMessage={translate(
              loadingHistoryData && calledHistoryData ? '' : 'NOTHING_FOUND'
            )}
            renderEmptyDataMessage={
              hasHistoryCalls === false && !hasSearchParams && renderEmptyContent()
            }
            beforeRowRenderer={renderBeforeRowContent}
            customRowHeight={isDayChanged}
            rowClick={rowClick}
          />
        </Flex>
        <Routes>
          <Route path={'welcome'} element={<WelcomeDialog />} />
        </Routes>
      </Flex>
    </BodyContainer>
  );
};

export default CompanyCalls;

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AlertCircleIcon, CheckIcon, ClockIcon } from '@shared/assets/images/icons';
import { CLIENT_QUERY, GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@shared/components/Button/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import BodyContainer from '@/layouts/BodyContainer';
import { useRoutes } from '@components/Routes';
import { ClientStatus, DomainDocsModel, DomainStatus } from '@/client/generated/graphql';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { ButtonProps } from '@shared/components/Button';
import { formType, stepsNamesList } from './Contract.constants';
import { useContractStyle } from './Contract.styles';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  childrenHeight?: number | undefined;
  formId?: string;
  continueButtonProps?: ButtonProps;
};

export const ContractLayout = ({
  children,
  isLoading,
  childrenHeight = 0,
  formId = formType.PersonalData,
  continueButtonProps,
}: Props) => {
  const classes = useContractStyle();
  const [translate] = useTranslation();
  const { data: domainData } = useQuery(GET_SELECTED_TARIFF_QUERY);
  const { data: userData } = useQuery(USER_QUERY);
  const { data: clientData, loading: loadingClientData } = useQuery(CLIENT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const isModerated = clientData?.getClient.isModerated;
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const status = getCurrentDomain(userData?.user)?.status;
  const navigate = useNavigate();
  const [fullHeight, setFullHeight] = useState(false);
  const isWideSidebar = sidebarState?.isWide;
  const {
    path: { subcategory },
  } = useRoutes();
  const confirmStep = subcategory === 'confirm';
  const loading = isLoading || loadingClientData;

  const onResize = useCallback(() => {
    if (childrenHeight) {
      if (childrenHeight + 80 < window.innerHeight && !fullHeight) {
        setFullHeight(true);
      }
      if (childrenHeight + 80 > window.innerHeight && fullHeight) {
        setFullHeight(false);
      }
    }
  }, [fullHeight, childrenHeight]);

  const computedRootClasses = clsx({
    [classes.contractRoot]: !fullHeight,
    [classes.contractRootFullHeight]: fullHeight,
  });

  useEffect(() => {
    if (status && status === DomainStatus.Moderating) {
      navigate('/documents/documents-list');
    }
  }, [status, navigate]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize, childrenHeight]);

  const renderSteps = useMemo(() => {
    const docs = domainData?.getDomain?.params?.docs;
    return stepsNamesList.map((item, ind) => {
      const index = ind + 1;
      const isNotLastStep = item.key !== 'check';
      const clientStatus = userData?.user.domains.find((d) => d.current)?.client?.status;
      const isDataFilled =
        (clientStatus === ClientStatus.Commercial && index === 1) ||
        (docs && !!docs[item.key as keyof DomainDocsModel]);
      const selectedStep = item.path.includes(subcategory as string);

      const renderIcon = () => {
        if (item.step === 1) {
          if (isModerated === false) {
            return <ClockIcon />;
          }
        }
        if (isDataFilled && isNotLastStep) {
          return (
            <CheckIcon className={selectedStep ? classes.checkIconBlack : classes.checkIcon} />
          );
        }
        if (!isNotLastStep && selectedStep) {
          return <AlertCircleIcon />;
        }
        return (
          <Typography type={'text3'} color={'tertiary900'}>
            {index}
          </Typography>
        );
      };

      const getTitle = () => {
        if (item.step !== 1) {
          return translate(item.title);
        }

        switch (isModerated) {
          case false: {
            return translate('DATA_IS_BEING_CHECKED');
          }
          case true: {
            return translate('DATA_HAS_BEEN_CHECKED');
          }
          case null:
          case undefined:
          default: {
            return translate(item.title);
          }
        }
      };

      return (
        <div key={index} className={classes.stepItem}>
          <div
            className={clsx(classes.stepIcon, {
              [classes.stepIconCurrentBg]: selectedStep,
              [classes.stepIconSuccessBg]:
                isDataFilled && !selectedStep && !(item.step === 1 && isModerated === false),
              [classes.stepIconLastBg]: !isNotLastStep && !confirmStep,
            })}
          >
            {renderIcon()}
          </div>
          <div className={isNotLastStep ? classes.stepTextPoint : classes.stepText}>
            <Typography
              type={'text3'}
              color={index !== 5 || confirmStep ? 'tertiary900' : 'tertiary400'}
              underline={isNotLastStep}
              onClick={() => {
                if (isNotLastStep && !loading) {
                  navigate(item.path);
                }
              }}
            >
              {getTitle()}
            </Typography>
          </div>
        </div>
      );
    });
  }, [
    domainData?.getDomain?.params?.docs,
    userData?.user.domains,
    subcategory,
    classes,
    isModerated,
    confirmStep,
    translate,
    loading,
    navigate,
  ]);

  return (
    <BodyContainer
      customOutletClass={classes.contractCustomOutlet}
      customRootClass={classes.contractCustomRoot}
    >
      <div className={computedRootClasses}>
        <div className={classes.root}>{children}</div>
        <div
          className={clsx(classes.documentSteps, { [classes.wideSidebarMargin]: isWideSidebar })}
        >
          <div className={classes.documentStepsLeft}>{renderSteps}</div>
          <div className={classes.documentStepsRight}>
            <Button
              title={translate('SAVE_AND_CONTINUE')}
              form={formId}
              variant={'primary'}
              disabled={confirmStep}
              type={'submit'}
              loading={loading}
              {...continueButtonProps}
            />
          </div>
        </div>
      </div>
    </BodyContainer>
  );
};

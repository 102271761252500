export enum DocumentsType {
  Contract = 'CONTRACT',
  CommunicationsContract = 'COMMUNICATIONS_CONTRACT',
  Order = 'ORDER',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  Refusal = 'REFUSAL',
  Scan = 'SCAN',
  SidesReplacement = 'SIDES_REPLACEMENT',
  TariffChange = 'TARIFF_CHANGE',
  TerminationContract = 'CONTRACT_TERMINATION',
}

import { CSSProperties } from 'react';
import { AssistantType } from '@components/typings/interfaces';
import { TariffModel } from '@/client/generated/graphql';
import { scenarioInterface, scenarioListType } from './Contract.interfaces';

export const stepsNamesList = [
  { title: 'ENTER_DATA', key: 'passport', path: '/register/personal-data', step: 1 },
  { title: 'CHOOSE_TARIFF', key: 'tariff', path: '/register/select-tariff', step: 2 },
  { title: 'CHOOSE_NUMBER', key: 'numbers', path: '/register/phone-number', step: 3 },
  { title: 'CHOOSE_SCENARIO_TITLE', key: 'assistants', path: '/register/scenario', step: 4 },
  { title: 'CHECK', key: 'check', path: '/register/confirm', step: 5 },
];

export const MAX_ACCOUNT_AMOUNT = 999;

const bankDetails = {
  labelName: 'BANK_DETAILS',
  fieldName: '',
  fields: [
    {
      labelName: 'BANK_NAME',
      fieldName: 'bankName',
    },
    {
      labelName: 'BIK',
      fieldName: 'bik',
    },
    {
      labelName: 'CHECKING_ACCOUNT',
      fieldName: 'account',
    },
    {
      labelName: 'CORRESPONDENT_ACCOUNT',
      fieldName: 'correspondentAccount',
    },
    {
      labelName: 'BANK_ADDRESS',
      fieldName: 'bankAddress',
    },
  ],
};

export const FlFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'FULL_NAME',
        fieldName: 'person',
      },
      {
        labelName: 'GENITIVE_NAME',
        fieldName: 'genitiveCasePerson',
      },
      {
        labelName: 'DATE_OF_BIRTH',
        fieldName: 'dateOfBirth',
        format: 'date',
      },
      {
        labelName: 'GENDER',
        fieldName: 'gender',
        format: 'gender',
      },
      {
        labelName: 'INN',
        fieldName: 'inn',
      },
      {
        labelName: 'SERIES',
        fieldName: 'series',
      },
      {
        labelName: 'PAS_NUMBER',
        fieldName: 'number',
      },
      {
        labelName: 'CODE',
        fieldName: 'departmentCode',
      },
      {
        labelName: 'WHO_GIVEN',
        fieldName: 'placeOfIssue',
      },
      {
        labelName: 'GIVEN_DATE',
        fieldName: 'dateOfIssue',
        format: 'date',
      },
      {
        labelName: 'REGISTRATION_ADDRESS',
        fieldName: 'legalAddress',
      },
      {
        labelName: 'LIVING_ADDRESS',
        fieldName: 'postalAddress',
      },
    ],
  },
  {
    labelName: 'MENU_CONTACTS',
    fieldName: '',
    fields: [
      {
        labelName: 'PHONE',
        fieldName: 'phone',
        format: 'phone',
      },
      {
        labelName: 'EMAIL',
        fieldName: 'email',
      },
    ],
  },
];

export const IpFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'COMPANY_NAME',
        fieldName: 'companyName',
      },
      {
        labelName: 'FULL_NAME',
        fieldName: 'person',
      },
      {
        labelName: 'GENITIVE_NAME',
        fieldName: 'genitiveCasePerson',
      },
      {
        labelName: 'DATE_OF_BIRTH',
        fieldName: 'dateOfBirth',
        format: 'date',
      },
      {
        labelName: 'GENDER',
        fieldName: 'gender',
        format: 'gender',
      },
      {
        labelName: 'SERIES',
        fieldName: 'series',
      },
      {
        labelName: 'PAS_NUMBER',
        fieldName: 'number',
      },
      {
        labelName: 'CODE',
        fieldName: 'departmentCode',
      },
      {
        labelName: 'WHO_GIVEN',
        fieldName: 'placeOfIssue',
      },
      {
        labelName: 'GIVEN_DATE',
        fieldName: 'dateOfIssue',
        format: 'date',
      },
      {
        labelName: 'INN',
        fieldName: 'inn',
      },
      {
        labelName: 'OGRNIP',
        fieldName: 'ogrn',
      },
      {
        labelName: 'EDO_IDENTIFIER',
        fieldName: 'edo',
      },
      {
        labelName: 'LEGAL_ADDRESS',
        fieldName: 'legalAddress',
      },
      {
        labelName: 'POSTAL_ADDRESS',
        fieldName: 'postalAddress',
      },
    ],
  },

  bankDetails,

  {
    labelName: 'MENU_CONTACTS',
    fieldName: '',
    fields: [
      {
        labelName: 'PHONE',
        fieldName: 'phone',
        format: 'phone',
      },
      {
        labelName: 'EMAIL',
        fieldName: 'email',
      },
    ],
  },
];

export const UlFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'COMPANY_NAME',
        fieldName: 'companyName',
      },
      {
        labelName: 'INN',
        fieldName: 'inn',
      },
      {
        labelName: 'OGRN',
        fieldName: 'ogrn',
      },
      {
        labelName: 'EDO_IDENTIFIER',
        fieldName: 'edo',
      },
      {
        labelName: 'KPP',
        fieldName: 'kpp',
      },

      {
        labelName: 'LEGAL_ADDRESS',
        fieldName: 'legalAddress',
      },
      {
        labelName: 'POSTAL_ADDRESS',
        fieldName: 'postalAddress',
      },
    ],
  },

  bankDetails,

  {
    labelName: 'SIGNATORY',
    fieldName: '',
    fields: [
      {
        labelName: 'FULL_NAME',
        fieldName: 'person',
      },
      {
        labelName: 'GENITIVE_NAME',
        fieldName: 'genitiveCasePerson',
      },
      {
        labelName: 'POSITION',
        fieldName: 'position',
      },
      {
        labelName: 'POSITION_GENITIVE',
        fieldName: 'genitiveCasePosition',
      },
      {
        labelName: 'BASIC_CONTRACT_GENITIVE',
        fieldName: 'actBasis',
      },
    ],
  },
  {
    labelName: 'MENU_CONTACTS',
    fieldName: '',
    fields: [
      {
        labelName: 'PHONE',
        fieldName: 'phone',
        format: 'phone',
      },
      {
        labelName: 'EMAIL',
        fieldName: 'email',
      },
    ],
  },
];

export enum formType {
  PersonalData = 'personalData',
  Tariff = 'tariff',
  PhoneNumber = 'phoneNumber',
  Scenario = 'scenario',
}

export enum scenarioTypes {
  Base = 'Базовый',
  Individual = 'Индивидуальный',
}

export const scenarioList: scenarioListType[] = [
  {
    title: 'SCENARIOS_BASE_TITLE',
    description: 'SCENARIO_BASE_DESCRIPTION',
    monthPrice: 5000,
    installationPrice: 5000,
    type: scenarioTypes.Base,
    assistantType: AssistantType.Market,
  },
  {
    title: 'SCENARIOS_INDIVIDUAL_TITLE',
    description: 'SCENARIO_INDIVIDUAL_DESCRIPTION',
    monthPrice: 10000,
    type: scenarioTypes.Individual,
    assistantType: AssistantType.Individual,
    flexiblePrice: true,
  },
];

export const schemaType: scenarioInterface = {
  [scenarioTypes.Base]: { title: 'EXAMPLE_SCENARIOS' },
  [scenarioTypes.Individual]: { title: 'EXAMPLE_SCENARIOS' },
};

export const individualScenarios = {
  audio: '/audio/individual.mp3',
};

export const setTopStyle = (style: CSSProperties, padding: number): string | number => {
  if (typeof style.top === 'string') {
    return `${parseFloat(style.top) + padding}px`;
  }
  if (typeof style.top === 'number') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `${style.top + padding}px`;
  }
  return 0;
};

export const getAccountsInformation = (tariffModel: TariffModel, accountType: string) =>
  tariffModel.options.filter((i: { type: string }) => i.type === accountType)[0];

import { makeStyles } from '@material-ui/core';

export const useMessageDialogStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    content: {
      width: '22em',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '2.75em',
      paddingLeft: '1.5em',
      paddingRight: '.75em',
      borderBottom: `1px solid ${tertiary[200]}`,
    },
    bodyWrapper: {
      padding: '1.25em 1.5em 1.5em',
    },
    buttonClose: {
      fontSize: '1em',
      width: '2em',
      minWidth: 'unset',
      height: '2em',
      '& path': {
        transition: 'fill .3s ease-out',
      },
      '&:hover path': {
        fill: primary[700],
      },
    },
    paper: {
      borderRadius: '0.75em',
    },
    centerBlock: {
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
    },
    buttonBlock: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      height: '2em',
      marginTop: '2em',
    },
    buttonControl: {
      height: '2em',
    },
    buttonControlMargin: {
      marginRight: '.5em',
    },
  }),
  {
    index: 1,
  }
);

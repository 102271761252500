import React, { FunctionComponent } from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';
import clsx from 'clsx';
import { useTooltipStyles } from './Tooltip.styles';

export type TooltipProps = MuiTooltipProps & {
  tooltipClass?: string;
  tooltipAdditionalClass?: string;
  arrowClass?: string;
  contentClass?: string;
  containerClass?: string;
};

export const TooltipHover: FunctionComponent<TooltipProps> = ({
  children,
  title = '',
  placement = 'top-end',
  tooltipClass,
  tooltipAdditionalClass,
  arrowClass,
  contentClass,
  containerClass,
  ...props
}) => {
  const classes = useTooltipStyles();

  const renderTitle = () => (
    <div className={clsx(classes.container, containerClass)}>
      <div className={clsx(classes.content, contentClass)}>{title}</div>
    </div>
  );

  return (
    <MuiTooltip
      classes={{
        tooltip: clsx(
          classes.tooltipBase,
          tooltipClass,
          { [classes.tooltip]: !tooltipClass },
          tooltipAdditionalClass
        ),
        arrow: arrowClass || classes.arrow,
      }}
      placement={placement}
      title={renderTitle()}
      arrow
      {...props}
    >
      <div className={classes.anchorContainer}>{children}</div>
    </MuiTooltip>
  );
};

export default TooltipHover;

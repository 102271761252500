import { RESERVE_NUMBER_MUTATION } from '@/client/mutations';
import {
  DOMAIN_TEL_NUMBERS_QUERY,
  GET_CODE_LIST_QUERY,
  GET_NUMBER_LIST_QUERY,
} from '@/client/queries';
import {
  CORE_CITY_PREFIX,
  DIVIDER_ITEM,
} from '@/components/DrawerReserveNumbers/DrawerReserveNumbers.constants';
import {
  ICellState,
  ICodeListItem,
  ICodeListItemComputed,
  PhoneNumber,
} from '@/features/Documents/Contract/Contract.interfaces';
import { PhoneNumberLazyScrollTable } from '@/features/Documents/Contract/modules';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Drawer from '@components/Drawer';
import { NumberCategory } from '@components/typings/interfaces';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { toPrecision } from '@components/utils/toPrecision';
import { IconButton, InputAdornment } from '@material-ui/core';
import { CheckIcon, SearchIcon, XIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import ComboBoxField from '@shared/components/ComboBoxField';
import Preloader from '@shared/components/Preloader';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IDrawerButtonData } from '@components/Drawer/Drawer.interfaces';
import resultify from '@shared/utils/resultify';
import { NumberType } from '@/client/generated/graphql';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import FormFieldRhfControlled from '@shared/components/FormFieldRhfControlled';
import { useDrawerNumbersStyle } from './DrawerReserveNumbers.styles';
import {
  DomainTelNumber,
  IDrawerNumbersProps,
  NumberTypeList,
} from './DrawerReserveNumbers.interfaces';

const DrawerReserveNumbers = ({ open, onClose }: IDrawerNumbersProps) => {
  const classes = useDrawerNumbersStyle();

  const [translate] = useTranslation();
  const [searchParams] = useSearchParams();

  const gridListOuterElementRef = useRef<HTMLDivElement | null>(null);

  const sCode = searchParams.get('code');

  const [reserveNumber, { loading: loadingReserveNumber }] = useMutation(RESERVE_NUMBER_MUTATION);
  const { data: { getAvailableCityCodes: cityCodes = [] } = {} } = useQuery(GET_CODE_LIST_QUERY);
  const { data: domainTelNumbersQuery, refetch: updateNumbersList } =
    useQuery(DOMAIN_TEL_NUMBERS_QUERY);
  const reservedNumberList = domainTelNumbersQuery?.getNumbers || [];

  const reservedNumbersFiltered = reservedNumberList.filter((e) => {
    if (e.__typename === 'FmcNumberModel') {
      return false;
    }
    if (e.__typename === 'NumbersModel' && e.type === NumberType.Demo) {
      return false;
    }
    return true;
  }) as DomainTelNumber[];

  const {
    data: {
      searchNumbers: { rows: phoneNumberList = [], total: totalNumbersDirect = 0 } = {},
    } = {},
    refetch: fetchSearchNumbers,
    loading: loadingSearchNumbers,
  } = useQuery(GET_NUMBER_LIST_QUERY, {
    variables: {
      code: 495,
      category: NumberCategory.Classic,
      search: '',
      limit: 50,
      offset: null,
    },
    fetchPolicy: 'cache-first',
  });
  // TODO handle lazy query error
  const [
    getSearchNumbers,
    {
      data: { searchNumbers: { total: totalNumbersLazzy = 0 } = {} } = {},
      loading: loadingMoreNumbers,
    },
  ] = useLazyQuery(GET_NUMBER_LIST_QUERY);

  const cityCodesList = useMemo(() => {
    if (!cityCodes) return [];
    const { coreNumbers, otherNumbers } = cityCodes.reduce(
      (
        res: { coreNumbers: Array<ICodeListItem>; otherNumbers: Array<ICodeListItem> },
        item: ICodeListItem
      ) => {
        if (CORE_CITY_PREFIX.includes(item.code)) {
          res.coreNumbers.push(item);
        } else {
          res.otherNumbers.push(item);
        }
        return res;
      },
      { coreNumbers: [], otherNumbers: [] }
    );
    const sortedCityCodes = [
      ...coreNumbers,
      DIVIDER_ITEM,
      ...otherNumbers.sort((a: ICodeListItem, b: ICodeListItem) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      }),
    ];
    return sortedCityCodes.reduce((res: Array<ICodeListItemComputed>, item: ICodeListItem) => {
      res.push({
        ...item,
        computedName: `${item.code} - ${item.name}`,
      });
      return res;
    }, []);
  }, [cityCodes]);
  const formMethods = useForm({
    defaultValues: {
      // eslint-disable-next-line no-nested-ternary
      cityCode: cityCodesList.length ? (sCode ? parseInt(sCode, 10) : 44) : undefined,
      numberType: NumberCategory.Classic,
      numberPart: '',
    },
  });
  const { watch, setValue, reset } = formMethods;
  const cityCode = watch('cityCode');
  const numberType = watch('numberType');
  const numberPart = watch('numberPart');

  const [updatingNumbers, setUpdatingNumbers] = useState(false);
  const [tableRows, setTableRows] = useState(phoneNumberList);
  const [numberState, setSelectedNumber] = useState<ICellState>({
    isOpen: false,
    selectedNumber: null,
    selectedNumberIndex: null,
  });

  const counterTotal = () => {
    const selectedInList = reservedNumbersFiltered.reduce((result: number, item) => {
      if (tableRows.find((i) => i.phone === item.phone)) {
        return result + 1;
      }
      return result;
    }, 0);
    return totalNumbersDirect - selectedInList;
  };

  useEffect(() => {
    if (cityCodes) {
      const debounceData = setTimeout(() => {
        setUpdatingNumbers(true);
        if (gridListOuterElementRef.current) {
          gridListOuterElementRef.current.scrollTop = 0;
        }
        fetchSearchNumbers({
          code: cityCodes.find((e: ICodeListItem) => e.id === cityCode)?.code || 495,
          category: numberType || NumberCategory.Classic,
          search: numberPart || '',
          limit: 50,
          offset: null,
        }).then((response) => {
          const { data } = response;
          setTableRows(data?.searchNumbers?.rows);
          setUpdatingNumbers(false);
          setSelectedNumber({
            isOpen: false,
            selectedNumber: null,
            selectedNumberIndex: null,
          });
        });
      }, 300);

      return () => {
        clearTimeout(debounceData);
      };
    }
    return () => ({});
  }, [cityCode, numberType, fetchSearchNumbers, cityCodes, numberPart]);

  useEffect(() => {
    if (sCode) {
      reset(
        {
          cityCode: parseInt(sCode, 10),
          numberType: NumberCategory.Classic,
          numberPart: '',
        },
        { keepDirtyValues: true }
      );
    }
  }, [reset, sCode]);

  useEffect(() => {
    if (numberPart) {
      setValue('numberPart', numberPart.replace(/[^\d]/gm, ''));
    }
  }, [numberPart, setValue]);

  function handlePhoneNumberChooseMenuClose() {
    onClose();
    setValue('numberPart', '');
    setValue('numberType', NumberCategory.Classic);
    setValue('cityCode', 44);
  }

  function handleSearchNumberClear() {
    setValue('numberPart', '');
  }

  function getMorePhoneNumberItems() {
    getSearchNumbers({
      variables: {
        code: cityCodes.find((e: ICodeListItem) => e.id === cityCode)?.code || 495,
        category: numberType || NumberCategory.Classic,
        search: numberPart || '',
        limit: 50,
        offset: tableRows.length || 0,
      },
    }).then((res) => {
      setTableRows((prevState) => [...prevState, ...(res.data?.searchNumbers?.rows || [])]);
    });
    return null;
  }

  function hasNextPage() {
    const totalCount = Math.max(totalNumbersDirect, totalNumbersLazzy);
    if (totalCount === 0) {
      return false;
    }
    return totalCount > tableRows.length;
  }

  function handlePhoneSelect(numberItem: PhoneNumber, index: number) {
    setSelectedNumber({
      isOpen: true,
      selectedNumber: numberItem,
      selectedNumberIndex: index,
    });
  }

  function handleCancelPhoneSelect() {
    setSelectedNumber({
      isOpen: false,
      selectedNumber: null,
      selectedNumberIndex: null,
    });
  }

  async function handleReservePhone() {
    if (!numberState.selectedNumber) {
      return;
    }

    const result = await resultify(
      reserveNumber({
        variables: {
          catalogNumberId: numberState.selectedNumber.id,
          numberType: numberState.selectedNumber.type as NumberType,
        },
      })
    );

    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    setSelectedNumber({
      isOpen: false,
      selectedNumber: null,
      selectedNumberIndex: null,
    });
    updateNumbersList();
  }

  const renderCodeList = (cityCodeOption: ICodeListItemComputed) => {
    const { name, code, regionId, id } = cityCodeOption;
    const selected = cityCode === id;

    if (id === -1) {
      return <div className={classes.selectDivider} />;
    }

    if (!regionId) {
      return (
        <div className={classes.selectText}>
          <Typography color={'tertiary900'} type={'text3'} className={classes.selectNumberCode}>
            {name}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.selectItemCode}>
        {selected && <CheckIcon className={classes.selectIcon} />}
        <Typography
          color={selected ? 'primary700' : 'tertiary900'}
          type={'text3'}
          className={clsx(
            classes.selectNumberCode,
            !selected && classes.selectedNumberCodeMarginLeft
          )}
        >
          {code}
        </Typography>
        <Typography
          color={selected ? 'primary700' : 'tertiary400'}
          type={'text3'}
          className={classes.textOverflow}
        >
          {name}
        </Typography>
      </div>
    );
  };

  const renderSelectedNumber = () => {
    if (numberState.selectedNumber) {
      const {
        selectedNumber: { tariff, phone, city, category, type },
      } = numberState;

      const smsReceiveIsNotSupported =
        type === NumberType.Gravitel && String(phone).startsWith('79');
      const is8800 = type === NumberType.GravitelCommon;

      return (
        <>
          <div className={classes.phoneNumberCancelButtonBlock}>
            <Button
              className={classes.phoneNumberCancelButton}
              onClick={handleCancelPhoneSelect}
              variant="tertiary"
            >
              <XIcon />
            </Button>
          </div>
          <div className={classes.phoneNumberBlock}>
            <div className={classes.phoneNumberLabelWrapper}>
              <Typography pxToEmSize={12} color="tertiary600">
                {translate('NUMBER')}
              </Typography>
            </div>

            <Typography type={'text2'} color={'tertiary900'}>
              {formatPhone(phone)}
            </Typography>

            <div className={classes.phoneNumberSmsReceiveNotSupportedLabel}>
              {(smsReceiveIsNotSupported || is8800) && (
                <>
                  <Typography pxToEmSize={16} color="tertiary600">
                    *
                  </Typography>
                  <Typography pxToEmSize={12} color="tertiary600">
                    {smsReceiveIsNotSupported
                      ? translate('NUMBERS_NO_SMS_NUMBERS')
                      : translate('NUMBERS_ONLY_INCOMING')}
                  </Typography>
                </>
              )}
            </div>

            <div className={classes.phoneNumberSelectLine}>
              <div className={classes.phoneNumberInformation}>
                <Typography type={'text4'} color={'tertiary600'}>
                  {translate('COST')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'}>
                  {toPrecision((tariff?.baseSetupFee ?? tariff?.setupFee) || 0)} ₽
                </Typography>
              </div>
              <div className={classes.phoneNumberInformation}>
                <Typography type={'text4'} color={'tertiary600'}>
                  {translate('MONTH_PRICE')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'}>
                  {toPrecision((tariff?.baseMonthlyFee ?? tariff?.monthlyFee) || 0)} ₽
                </Typography>
              </div>
              <div className={classes.phoneNumberInformation}>
                <Typography type={'text4'} color={'tertiary600'}>
                  {translate('CITY')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'}>
                  {city}
                </Typography>
              </div>
              <div className={classes.phoneNumberInformation}>
                <Typography type={'text4'} color={'tertiary600'}>
                  {translate('TYPE')}
                </Typography>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate(`NUMBER_${category}`)}
                </Typography>
              </div>
            </div>
          </div>
        </>
      );
    }

    return <div />;
  };

  const numberPartEndAdorment = (
    <InputAdornment position={'end'}>
      <IconButton edge={'end'} color={'inherit'} size={'small'} onClick={handleSearchNumberClear}>
        {numberPart && numberPart.length ? <XIcon /> : <SearchIcon />}
      </IconButton>
    </InputAdornment>
  );

  const isNumberReserved =
    numberState.selectedNumber &&
    reservedNumbersFiltered.find((num) => num.phone === String(numberState.selectedNumber?.phone));

  const drawerPrimaryButtonProps: IDrawerButtonData | undefined =
    numberState.isOpen && !isNumberReserved
      ? {
          title: 'RESERVE',
          onClick: handleReservePhone,
          props: {
            className: classes.phoneNumberReserveButton,
            loading: loadingReserveNumber,
          },
        }
      : undefined;

  return (
    <Drawer
      title="AVAILABLE_NUMBER"
      anchor={'right'}
      open={open}
      onClose={handlePhoneNumberChooseMenuClose}
      classes={{ paper: classes.phoneNumberRightMenu }}
      footerClass={classes.footer}
      contentClass={classes.content}
      primaryButton={drawerPrimaryButtonProps}
      tertiaryButton={{
        title: 'CLOSE',
        onClick: handlePhoneNumberChooseMenuClose,
      }}
    >
      <FormProvider {...formMethods}>
        <div className={classes.phoneNumberMenuFilter}>
          <div className={classes.phoneNumberFilterItem}>
            <ComboBoxField
              name={'cityCode'}
              valueKey={'id'}
              titleKey={'computedName'}
              getOptionDisabled={(opt) => opt.id === -1}
              classes={{ root: classes.phoneNumberCityCode }}
              renderOption={renderCodeList}
              data={cityCodesList}
              placeholder={translate('ENTER_PREFIX_OF_CITY')}
              validate={(value: string) => {
                if (!value) {
                  return translate('ENTER_PREFIX_OF_CITY') as string;
                }
                return true;
              }}
              filterOptions={(options, state) => {
                if (!state.inputValue) return options;
                const inputValueLowerCased = state.inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.code.toString().includes(inputValueLowerCased) ||
                    option.name.toLowerCase().includes(inputValueLowerCased)
                );
              }}
            />
            <SelectField
              name={'numberType'}
              valueKey={'id'}
              titleKey={'name'}
              translating
              SelectProps={{ classes: { root: classes.selectRoot } }}
              defaultValue={NumberCategory.Classic}
              data={NumberTypeList}
              className={classes.phoneNumberType}
            />
          </div>
          <div className={classes.phoneNumberFilterItem}>
            <FormFieldRhfControlled
              name={'numberPart'}
              placeholder={translate('SEARCH_BY_NUMBER_PART')}
              className={classes.phoneNumberPart}
              onChange={(e) => {
                const maxSafePhoneLength = 16;
                if (e.target.value.length > maxSafePhoneLength) {
                  e.target.value = e.target.value.slice(0, maxSafePhoneLength);
                }
                e.target.value = e.target.value.replace(/[^\d]/g, '');
              }}
              InputProps={{ endAdornment: numberPartEndAdorment }}
            />
          </div>
        </div>
        <div
          className={clsx(classes.phoneNumberMenuContent, {
            [classes.phoneNumberMenuContentOpen]: numberState.isOpen,
          })}
        >
          {!loadingSearchNumbers && (
            <div className={classes.phoneNumberCounterWrap}>
              <div className={classes.phoneNumberCounter}>
                {tableRows && tableRows.length ? (
                  <>
                    <Typography type={'text3'} color={'tertiary500'}>
                      {translate('AVAILABLE_NUMBER_COUNT')}
                    </Typography>
                    <Typography type={'text3'} color={'tertiary900'}>
                      {counterTotal()}
                    </Typography>
                  </>
                ) : (
                  <Typography type={'text3'} color={'tertiary500'}>
                    {translate('NUMBERS_NOT_FOUND')}
                  </Typography>
                )}
              </div>
            </div>
          )}
          {(loadingSearchNumbers || updatingNumbers) && (
            <div className={classes.phoneNumberListPreloader}>
              <Preloader />
            </div>
          )}
          {!!(tableRows && tableRows.length) && (
            <PhoneNumberLazyScrollTable
              allDisabled={loadingReserveNumber}
              items={tableRows}
              loadNextPage={getMorePhoneNumberItems}
              hasNextPage={hasNextPage()}
              isNextPageLoading={loadingMoreNumbers || loadingSearchNumbers}
              onNumberSelect={handlePhoneSelect}
              selectedNumbers={reservedNumbersFiltered}
              selectedItem={numberState.selectedNumberIndex}
              gridListOuterElementRef={gridListOuterElementRef}
            />
          )}
        </div>
        <div
          className={clsx(classes.phoneNumberSelectDialog, {
            [classes.phoneNumberSelectDialogOpen]: numberState.isOpen,
          })}
        >
          {renderSelectedNumber()}
        </div>
      </FormProvider>
    </Drawer>
  );
};

export default DrawerReserveNumbers;

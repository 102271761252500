import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button/Button';
import { toPrecision } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { FormArrowSwitcher } from '@components/FormArrowSwitcher/FormArrowSwitcher';
import clsx from 'clsx';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { XIcon, CheckIcon, PlayIcon } from '@shared/assets/images/icons';
import MessageDialog from '@shared/components/MessageDialog';
import { Modal } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import { CHOOSE_ASSISTANTS_IN_DEMO_MUTATION } from '@/client/mutations';
import { BASIC_INCOMING_SCENARIOS, BASIC_SCENARIOS } from '@/../typings/basicScenarios';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { ContractLayout } from '@/features/Documents';
import { ClientStatus, ReserveAssistantInDemo, DomainDocsModel } from '@/client/generated/graphql';
import {
  formType,
  individualScenarios,
  MAX_ACCOUNT_AMOUNT,
  scenarioList,
  scenarioTypes,
  schemaType,
  stepsNamesList,
} from '../Contract.constants';
import { useContractStyle } from '../Contract.styles';
import { IScenarioContent, IScenarioState } from '../Contract.interfaces';

export const ContractScenario = () => {
  const classes = useContractStyle();
  const formMethods = useForm<{ scenarioNumbers: string[] }>();
  const [translate] = useTranslation();
  const { data: userData, loading: loadingUserData } = useQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: domain,
    refetch: refetchDomainData,
    loading: loadingSelectedTariffQuery,
  } = useQuery(GET_SELECTED_TARIFF_QUERY);

  const loading = loadingUserData || loadingSelectedTariffQuery;

  const domainData = getCurrentDomain(userData?.user);
  const assistants = useMemo(() => domainData?.settings?.assistants || [], [domainData]);
  const [reserveAssistant] = useMutation(CHOOSE_ASSISTANTS_IN_DEMO_MUTATION);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const { handleSubmit, watch, setValue } = formMethods;
  const scenarioSelectList = watch('scenarioNumbers');
  const navigate = useNavigate();
  const [scenarioDialog, setScenarioDialog] = useState<IScenarioState>({
    isOpen: false,
    scenarioType: null,
    fixedPrice: 0,
    amountPrice: 0,
  });
  const [schemaIndex, setSchemaIndex] = useState<null | number>(null);
  const [activeList, setActiveList] = useState('OUTGOING');
  const [activePlayerIndex, setActivePlayerIndex] = useState<number | undefined>(undefined);
  const [isAudioIndividualPlay, setIsAudioIndividualPlay] = useState(false);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const actualRenderContent =
    activeList === 'OUTGOING' ? BASIC_SCENARIOS : BASIC_INCOMING_SCENARIOS;

  const getMinStepPath = useMemo(() => {
    let minStep = 1;
    let allPagesFilled = true;
    const stepsObj: { [key: string]: number } = {
      passport: 1,
      tariff: 2,
      numbers: 3,
      assistants: 4,
    };

    const docs = domain?.getDomain?.params?.docs;

    const clientStatus = userData?.user.domains.find((d) => d.current)?.client?.status;

    if (docs) {
      const domainDocs = { ...docs, passport: clientStatus === ClientStatus.Commercial };
      Object.keys(domainDocs).forEach((el) => {
        if (domainDocs[el as keyof DomainDocsModel] === null) {
          allPagesFilled = false;
          const stepIndex = stepsObj[el];
          minStep = minStep < stepIndex ? minStep : stepIndex;
        }
      });
    } else {
      allPagesFilled = false;
    }
    return (
      stepsNamesList.find((el) => el.step === (allPagesFilled ? 5 : minStep))?.path ||
      '/register/personal-data'
    );
  }, [domain, userData?.user]);

  const scenarioModalClass = clsx({
    [classes.exampleScenarioDialogBasis]: scenarioDialog.scenarioType === scenarioTypes.Base,
    [classes.exampleScenarioDialogIndividual]:
      scenarioDialog.scenarioType === scenarioTypes.Individual,
  });

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Finish) navigate(blockedPath || getMinStepPath);
  }, [isChanged, navigate, getMinStepPath, blockedPath]);

  useEffect(() => {
    if (assistants && !loading) {
      scenarioList.forEach((item, index) => {
        const filteredScenarioCount = assistants.find(
          (i: { type: string }) => i.type === item.assistantType
        );
        if (filteredScenarioCount) {
          setValue(`scenarioNumbers.${index}`, String(filteredScenarioCount.count));
        }
      });
    }
  }, [assistants, setValue, loading]);

  useEffect(() => {
    if (activeList) {
      setActivePlayerIndex(undefined);
    }
  }, [activeList]);

  function handleOpenMessageDialog(
    scenarioType: scenarioTypes,
    fixedPrice: number,
    amountPrice: number
  ) {
    setScenarioDialog({ isOpen: true, scenarioType, fixedPrice, amountPrice });
  }

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function handleCloseMessageDialog() {
    setScenarioDialog({ isOpen: false, scenarioType: null, amountPrice: 0, fixedPrice: 0 });
    setActivePlayerIndex(undefined);
    setIsAudioIndividualPlay(false);
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleCloseSchemaDialog() {
    setSchemaIndex(null);
  }

  function handleExampleStartPlay(index: number) {
    setActivePlayerIndex(index);
  }

  function handleIndividualExampleStartPlay() {
    setIsAudioIndividualPlay(true);
  }

  async function handleScenarioSubmit(formData: { scenarioNumbers: Array<string> }) {
    const assistantDataList: ReserveAssistantInDemo[] = [];
    formData.scenarioNumbers.forEach((item, index) => {
      const numericValue = Number(item);
      if (numericValue !== 0) {
        assistantDataList.push({
          type: scenarioList[index].assistantType,
          count: numericValue,
        });
      }
    });
    reserveAssistant({
      variables: {
        data: {
          assistants: assistantDataList,
        },
      },
    }).then(() => {
      refetchDomainData().then(() => {
        setIsChanged({
          isBlocking: false,
          action: ConfirmAction.Finish,
        });
      });
    });
  }

  const renderScenarioCard = (el: IScenarioContent, index: number) => (
    <div key={`scenarioCard-${index}`} className={classes.messageDialogCellBlock}>
      <div className={classes.messageDialogTextBlock}>
        <div className={classes.messageDialogBasisTextContainer}>
          <div className={classes.messageDialogTitleIndent}>
            <Typography type={'default'} color={'tertiary900'} bold>
              {translate(el.title)}
            </Typography>
          </div>
          <Typography type={'text4'} color={'tertiary900'}>
            {translate(el.description)}
          </Typography>
        </div>
        <div className={classes.messageDialogFeeContainer}>
          <div className={classes.messageDialogFeeBlock}>
            <Typography type={'text4'} color={'tertiary500'}>
              {translate('INST_WORKS')}
            </Typography>
            <Typography type={'text4'} color={'tertiary900'} bold>
              {scenarioDialog.fixedPrice} ₽
            </Typography>
          </div>
          <div className={classes.messageDialogFeeBlock}>
            <Typography type={'text4'} color={'tertiary500'}>
              {translate('MONTH_PRICE')}
            </Typography>
            <Typography type={'text4'} color={'tertiary900'} medium>
              {translate('RUB_PER_MONTH', { amount: scenarioDialog.amountPrice })}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.messageDialogControlsContainer}>
        {activePlayerIndex !== index ? (
          <div className={classes.messageDialogRowContent}>
            <div className={classes.messageDialogBasisButtonsBlock}>
              <div className={classes.messageDialogBtnWrapper}>
                <Button
                  size={'small'}
                  variant={'secondary'}
                  className={classes.audioListenSmallControlButton}
                  onClick={() => handleExampleStartPlay(index)}
                >
                  <PlayIcon />
                </Button>
                <Button
                  title={translate('SCHEMA')}
                  variant={'secondary'}
                  className={classes.schemaControlButton}
                  onClick={() => {
                    setSchemaIndex(index);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.messageDialogRowContent}>
            <AudioPlayer
              source={el.audio}
              isPaused={false}
              isExpanded
              collapseOnEnd={false}
              side={'left'}
              autoPlay
            />
            <XIcon className={classes.closeIcon} onClick={() => setActivePlayerIndex(undefined)} />
          </div>
        )}
      </div>
    </div>
  );

  const renderIndividualScenarioCard = () => (
    <div className={classes.messageDialogCellBlock}>
      <div className={classes.messageDialogTextContainer}>
        <Typography type={'text3'} bold color={'tertiary900'}>
          {translate('TITLE_INDIVIDUAL_SCENARIOS')}
        </Typography>
        <div className={classes.messageDialogRowsContainer}>
          <div className={classes.messageDialogRowContent}>
            <CheckIcon className={classes.messageDialogCheckIcon} />
            <Typography type={'text4'} color={'tertiary900'}>
              {translate('ANY_SOLUTION')}
            </Typography>
          </div>
          <div className={classes.messageDialogRowContent}>
            <CheckIcon className={classes.messageDialogCheckIcon} />
            <Typography type={'text4'} color={'tertiary900'}>
              {translate('COMPLEX_INDIVIDUAL_SCENARIOS')}
            </Typography>
          </div>
          <div className={classes.messageDialogRowContent}>
            <CheckIcon className={classes.messageDialogCheckIcon} />
            <Typography type={'text4'} color={'tertiary900'}>
              {translate('LINK_BOT_WITH_CRM')}
            </Typography>
          </div>
        </div>
      </div>
      {isAudioIndividualPlay ? (
        <div className={classes.messageDialogRowContent}>
          <AudioPlayer
            source={individualScenarios.audio}
            isPaused={false}
            isExpanded
            collapseOnEnd={false}
            side={'left'}
            autoPlay
          />
          <XIcon className={classes.closeIcon} onClick={() => setIsAudioIndividualPlay(false)} />
        </div>
      ) : (
        <div className={classes.messageDialogRowContent}>
          <Button
            variant={'secondary'}
            className={classes.audioListenExampleControlButton}
            onClick={() => handleIndividualExampleStartPlay()}
          >
            <PlayIcon className={classes.audioControlButtonIcon} />
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('LISTEN_EXAMPLE')}
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );

  const renderExamplesScenarioContent = () => {
    if (scenarioDialog.scenarioType === scenarioTypes.Base) {
      const computedClassesOutgoing = clsx(classes.scenarioTypeControlButton, {
        [classes.scenarioTypeControlButtonActiveColor]: activeList === 'OUTGOING',
      });
      const computedClassesIncoming = clsx(classes.scenarioTypeControlButton, {
        [classes.scenarioTypeControlButtonActiveColor]: activeList === 'INCOMING',
      });
      return (
        <div>
          <div className={classes.messageDialogRowContent}>
            <Button
              title={translate('OUTGOING')}
              variant={'secondary'}
              className={computedClassesOutgoing}
              onClick={() => setActiveList('OUTGOING')}
            />
            <Button
              title={translate('INCOMING')}
              variant={'secondary'}
              className={computedClassesIncoming}
              onClick={() => setActiveList('INCOMING')}
            />
          </div>
          <div className={classes.messageDialogBlocksContainer}>
            {actualRenderContent.map(renderScenarioCard)}
          </div>
        </div>
      );
    }
    if (scenarioDialog.scenarioType === scenarioTypes.Individual) {
      return renderIndividualScenarioCard();
    }
    return null;
  };

  const renderScenarioCards = () => {
    if (scenarioList) {
      return scenarioList.map((card, index) => {
        const scenarioNumber = Number(watch(`scenarioNumbers.${index}`));
        const isScenarioSelected = !Number.isNaN(scenarioNumber) && scenarioNumber > 0;
        return (
          <div
            key={`scenarioCard${index}`}
            className={clsx(classes.scenarioCard, {
              [classes.scenarioCardSelectedBg]: isScenarioSelected,
            })}
          >
            <div className={classes.scenarioCardLeftSide}>
              <div className={classes.scenarioCardLeftSideInformation}>
                <Typography
                  className={classes.scenarioCardScenarioName}
                  type={'text2'}
                  color={'tertiary900'}
                  bold
                >
                  {translate(card.title)}
                </Typography>
                <Typography type={'text3'} color={'tertiary600'}>
                  {translate(card.description)}
                </Typography>
                {card.title === 'SCENARIO_INDIVIDUAL_TITLE' && (
                  <Typography type={'text3'} color={'tertiary600'}>
                    {translate('SECOND_SCENARIO_INDIVIDUAL_DESC')}
                  </Typography>
                )}
              </div>
              <Button
                title={translate('EXAMPLES')}
                variant={'secondary'}
                classes={{ root: classes.btnScenarioExampleWidth }}
                onClick={() =>
                  handleOpenMessageDialog(card.type, card.installationPrice || 0, card.monthPrice)
                }
              />
            </div>
            <div className={classes.scenarioCardRightSide}>
              <div>
                <div className={classes.scenarioCardPriceBlock}>
                  <Typography type={'text4'} color={'tertiary500'}>
                    {translate('MONTH_PRICE')}
                  </Typography>
                  <Typography type={'text4'} color={'tertiary900'} medium>
                    {translate('RUB_PER_MONTH_SHORT', {
                      from: card.flexiblePrice ? translate('OF') : undefined,
                      amount: toPrecision(card.monthPrice, false),
                    })}
                  </Typography>
                </div>
                {card.installationPrice && (
                  <div className={classes.scenarioCardPriceBlock}>
                    <Typography type={'text4'} color={'tertiary500'}>
                      {translate('INST_WORKS')}
                    </Typography>
                    <Typography type={'text4'} color={'tertiary900'} medium>
                      {toPrecision(card.installationPrice, false)} ₽
                    </Typography>
                  </div>
                )}
              </div>
              <FormArrowSwitcher
                rootClass={classes.defaultElementWidth7}
                max={MAX_ACCOUNT_AMOUNT}
                name={`scenarioNumbers.${index}`}
                defaultValue={0}
                onChange={handleFormChange}
              />
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const renderScenarioTotals = () => {
    const { instPrice, abonPrice, isFlexible } = scenarioSelectList
      ? scenarioSelectList.reduce(
          (
            result: { instPrice: number; abonPrice: number; isFlexible: boolean },
            item: string,
            index: number
          ) => {
            const scenarioMonthPrice = scenarioList[index].monthPrice;
            const scenarioInstPrice = scenarioList[index].installationPrice;
            if (scenarioList[index].flexiblePrice) {
              return {
                ...result,
                isFlexible: Number(item) !== 0,
              };
            }
            return {
              abonPrice: scenarioMonthPrice
                ? result.abonPrice + scenarioMonthPrice * Number(item)
                : result.abonPrice,
              instPrice: scenarioInstPrice
                ? result.instPrice + scenarioInstPrice * Number(item)
                : result.instPrice,
              isFlexible: false,
            };
          },
          { instPrice: 0, abonPrice: 0, isFlexible: false }
        )
      : { instPrice: 0, abonPrice: 0, isFlexible: false };
    return (
      <div className={classes.scenarioTotals}>
        {isFlexible && (
          <div className={classes.scenarioTotalsWarning}>
            <Typography type={'text4'} color={'danger600'} bold>
              {translate('MANAGER_PRICE_MESSAGE')}
            </Typography>
          </div>
        )}
        <div className={classes.scenarioTotalsLine}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('TOTAL')}:
          </Typography>
          <div className={classes.scenarioTotalsAmount}>
            <div className={classes.scenarioTotalsItem}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('MONTH_PRICE')}
              </Typography>
              <Typography type={'text3'} color={'tertiary900'} medium>
                {translate('RUB_PER_MONTH_SHORT', { amount: toPrecision(abonPrice, false) })}
              </Typography>
            </div>
            <div className={classes.scenarioTotalsItem}>
              <Typography type={'text4'} color={'tertiary500'}>
                {translate('INST_WORKS')}
              </Typography>
              <Typography type={'text3'} color={'tertiary900'} medium>
                {toPrecision(instPrice, false)} ₽
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContractLayout
      isLoading={loading}
      childrenHeight={rootRef?.current?.clientHeight}
      formId={formType.Scenario}
    >
      <div ref={rootRef} className={classes.personalDataRoot}>
        <FormProvider {...formMethods}>
          <form id={formType.Scenario} onSubmit={handleSubmit(handleScenarioSubmit)}>
            <div className={classes.scenarioHeadContainer}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('CHOOSE_SCENARIO_TITLE')}
              </Typography>
              <Typography
                className={classes.scenarioCardDescriptionText}
                type={'text3'}
                color={'tertiary900'}
              >
                {translate('SCENARIO_DESCRIPTION')}
              </Typography>
            </div>
            <div className={classes.defaultElementWidth44}>
              {renderScenarioCards()}
              {renderScenarioTotals()}
            </div>
          </form>
        </FormProvider>
        <MessageDialog
          isOpen={scenarioDialog.isOpen}
          title={translate(schemaType[scenarioDialog.scenarioType || scenarioTypes.Base].title)}
          contentClass={scenarioModalClass}
          onCancel={handleCloseMessageDialog}
          renderContent={renderExamplesScenarioContent()}
          disableEnforceFocus
        />
        <Modal
          className={classes.modalContentContainer}
          hideBackdrop
          open={schemaIndex !== null}
          onClose={handleCloseSchemaDialog}
          aria-labelledby="child-modal-title"
        >
          <div className={classes.modalScenarioDialog}>
            <div className={classes.modalDialogRow}>
              <Typography id="child-modal-title" type={'text2'} color={'tertiary900'}>
                {`${translate('SCENARIO_SCHEMA')}: ${translate(
                  actualRenderContent[schemaIndex || 0].title
                )}`}
              </Typography>
              <XIcon className={classes.closeIcon} onClick={() => handleCloseSchemaDialog()} />
            </div>
            <div className={classes.modalScenarioImageOverlay}>
              <img
                src={actualRenderContent[schemaIndex || 0].schema}
                alt={actualRenderContent[schemaIndex || 0].title}
              />
            </div>
          </div>
        </Modal>
        <ConfirmDialog
          isBlocked={isChanged.isBlocking}
          onSaveChanges={handleSubmit(handleScenarioSubmit)}
          onNavigationBlocked={getBlockedPath}
        />
      </div>
    </ContractLayout>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useBalanceStyle = makeStyles(
  ({ color: { base, tertiary, secondary }, spacing, breakpoints: { down } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    balance: {
      height: '5em',
      position: 'fixed',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderTop: `solid 1px ${tertiary['100']}`,
      display: 'flex',
      padding: '0 2em',
      bottom: 0,
      right: 0,
      left: spacing(10),
      backgroundColor: base,
      boxShadow: '0px -5px 10px 6px rgba(238, 238, 238, 0.6)',
      [down('md')]: {
        right: '1em',
        bottom: '1.1em',
      },
    },
    balanceActions: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    balanceControls: {
      paddingLeft: '1em',
    },
    balanceNumber: {
      paddingLeft: '0.5em',
    },
    contentBottomSpace: {
      bottom: '5em',
    },
    currentBalanceMargin: {
      marginLeft: '9em',
    },
    financialInfo: {
      display: 'flex',
      backgroundColor: `${secondary['50']}`,
      border: `solid 1px ${secondary['200']}`,
      padding: '0.75em 1em',
      borderRadius: '0.5em',
      width: '100%',
      marginBottom: '0.25em',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    operationCell: {
      whiteSpace: 'pre-wrap',
    },
    rowWrapper: {
      paddingTop: '1em',
      paddingBottom: '1em',
      borderBottom: `1px solid ${tertiary[100]}`,
    },
    tableWrap: {
      width: '100%',
      height: '100%',
    },
    totalInfo: {
      minWidth: '16em',
      width: '16em',
    },
    totalInfoHead: {
      height: '2em',
      boxShadow: `inset 0px -1px 0px ${tertiary[100]}`,
      minHeight: '2em',
      backgroundColor: `${tertiary[50]}`,
    },
    totalInfoContent: {
      padding: '1.5em 2em',
      borderRight: `1px solid ${tertiary[200]}`,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    totalInfoRow: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25em',
      padding: '0.75em 0',
      borderBottom: `1px solid ${tertiary[200]}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);

import React from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { DocumentModel } from '@/client/generated/graphql';
import { DocumentsType } from '@components/typings/interfaces/documents';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { getCurrentClient } from '@/utils';

export const DocumentsNumberCell = ({ item }: ICellProps<DocumentModel>) => {
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { account = '' } = getCurrentClient(userData?.user) || {};
  if (item) {
    if (new Date(item.signingAt).getTime() > new Date('2024-11-01').getTime()) {
      switch (item.type) {
        case DocumentsType.Contract:
          return (
            <Typography color={'tertiary600'} type={'text3'}>
              {account}-Л
            </Typography>
          );
        case DocumentsType.CommunicationsContract:
          return (
            <Typography color={'tertiary600'} type={'text3'}>
              {account}-С
            </Typography>
          );
        default:
      }
    }
    return (
      <Typography color={'tertiary600'} type={'text3'}>
        {item.number}
      </Typography>
    );
  }

  return <div />;
};

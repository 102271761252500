import { InputBaseComponentProps, Popper, PopperProps } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete/Autocomplete';
import { CheckIcon, ChevronDownIcon } from '@shared/assets/images/icons';
import FormFieldBase from '@shared/components/FormFieldBase';
import Typography from '@shared/components/Typography/Typography';
import { ControllerRenderPropsCustom } from '@shared/types/forms';
import { mergeMultipleRefs } from '@shared/utils/react';
import clsx from 'clsx';
import React, { ChangeEvent, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { ComboBoxFieldProps } from './ComboBoxField.interfaces';
import {
  useAdditionalStyles,
  useComboBoxFieldOptionStyles,
  useComboBoxFieldStyles,
} from './ComboBoxField.styles';

export const ComboBoxField = <
  Data extends Record<string, any>[] = Record<string, any>[],
  DataOption extends Data[number] = Data[number],
  DataKey extends keyof DataOption = keyof DataOption,
  Value extends DataOption[DataKey] = DataOption[DataKey],
>({
  defaultValue, // do not pass default value, it should be set in useForm options - defaultValues, not there
  value: valueFromProps, // do not pass value there, use useForm name and value binding
  valueKey,
  titleKey = valueKey,
  name = '',
  data,
  groupBy,
  noOptionsText,
  onHighlightChange,
  validate,
  onChange,
  renderOption,
  onInputChange,
  required,
  freeSolo = false,
  showOptionsIfEmpty = true,
  loading,
  disabled,
  readonly,
  loadingText,
  classes: propsClasses,
  getOptionDisabled,
  filterOptions: filterOptionsFromProps,
  maxLength = 255,
  ...props
}: ComboBoxFieldProps<Data, DataOption, DataKey>) => {
  const { control } = useFormContext();
  const classes = useComboBoxFieldStyles();
  const classesAdditional = useAdditionalStyles();
  const [translate] = useTranslation();
  const optionClasses = useComboBoxFieldOptionStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInputBlurredRef = useRef(true);
  const defaultNoOptionsText = translate('NOTHING_FOUND');
  const defaultLoadingText = translate('SEARCHING');

  const computedClasses = {
    ...classes,
    root: clsx(classes.root, propsClasses?.root),
  };

  const getOptionLabel = (option: DataOption) => option[titleKey];

  const findAutocompleteOptionByValue = (value: Value) =>
    data.find((item) => (item as DataOption)[valueKey] === value);

  const defaultRenderOption = (
    option: DataOption,
    { selected, inputValue }: AutocompleteRenderOptionState
  ) => {
    const renderOptionText = option[titleKey];

    const textMatch = match(renderOptionText, inputValue, { insideWords: true });
    const textParsed = parse(renderOptionText, textMatch);

    return (
      <div className={optionClasses.root} key={option[valueKey]}>
        {selected && <CheckIcon className={optionClasses.glyph} />}
        <Typography
          color={selected ? 'primary700' : 'tertiary900'}
          type={'text3'}
          className={clsx(optionClasses.text, !selected && optionClasses.textMarginLeft)}
        >
          {textParsed.map((parsed, i) => {
            return (
              <span key={i} className={clsx(parsed.highlight && classesAdditional.highlight)}>
                {parsed.text}
              </span>
            );
          })}
        </Typography>
      </div>
    );
  };

  const renderAutocomplete = (controllerProps: ControllerRenderPropsCustom) => {
    const currentOption = findAutocompleteOptionByValue(controllerProps.field.value);

    const handleChange = (
      event: ChangeEvent<Record<string, unknown>>,
      option: DataOption | string,
      reason: AutocompleteChangeReason
    ) => {
      if (typeof option === 'object' && option !== null && option[valueKey] !== undefined) {
        controllerProps.field.onChange(option[valueKey]);
      }
      if (onChange) {
        onChange(event, option, reason);
      }
    };

    const handleInputChange = (
      // Eslint does not like `{}` type from material UI
      // eslint-disable-next-line @typescript-eslint/ban-types
      e: React.ChangeEvent<{}>,
      val: string,
      reason: AutocompleteInputChangeReason
    ) => {
      let resultValue = val;
      if (resultValue.length > maxLength) {
        resultValue = resultValue.slice(0, maxLength);
      }

      if (freeSolo) {
        controllerProps.field.onChange(resultValue);
      }
      if (onInputChange) {
        onInputChange(e, resultValue, reason);
      }
    };

    const getFilterOptions = () => {
      if (filterOptionsFromProps) {
        return filterOptionsFromProps;
      }
      const inputValue = controllerProps.field.value;
      if (inputValue === '' && !showOptionsIfEmpty) {
        return () => [];
      }
      // if (controllerProps.field.value) {
      //   return filterOptions(String(controllerProps.field.value)); // convert to string because it may potentially be a number
      // }
      return undefined;
    };

    const getOptionSelected = (option: DataOption) => {
      if (!currentOption) {
        return false;
      }
      return currentOption[valueKey as string] === option[valueKey];
    };

    const renderPopperComponent = (popperProps: PopperProps) => {
      const { children, ...rest } = popperProps;
      // TODO fix wrong type from popper
      const areMatchesInPopper = (children as any)?.props?.children[2] || null;
      if (!areMatchesInPopper && freeSolo) return null;
      return <Popper {...rest}>{children}</Popper>;
    };

    const currentValue = freeSolo ? undefined : currentOption;

    /** Important note - when we are using `freeSolo` prop, we must pass pure strings as options to MUI Autocomplete */
    return (
      <Autocomplete
        role="combobox"
        classes={computedClasses}
        loading={loading}
        loadingText={loadingText || defaultLoadingText}
        getOptionDisabled={getOptionDisabled}
        getOptionSelected={getOptionSelected}
        value={currentValue as NonNullable<string | DataOption> | undefined}
        options={data as DataOption[]}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        groupBy={groupBy}
        freeSolo={freeSolo}
        onBlur={() => {
          controllerProps.field.onBlur();
          isInputBlurredRef.current = true;
        }}
        onHighlightChange={onHighlightChange}
        noOptionsText={noOptionsText || defaultNoOptionsText}
        renderOption={renderOption || defaultRenderOption}
        filterOptions={getFilterOptions()}
        onChange={handleChange}
        onInputChange={handleInputChange}
        disableClearable
        popupIcon={<ChevronDownIcon />}
        renderInput={(params: AutocompleteRenderInputParams) => {
          let hasInputReceivedFirstClickAfterBlur = false;
          if (
            document.activeElement === inputRef.current &&
            inputRef.current !== null &&
            isInputBlurredRef.current
          ) {
            hasInputReceivedFirstClickAfterBlur = true;
            isInputBlurredRef.current = false;
          }
          const inputProps: InputBaseComponentProps = { ...params.inputProps };

          if (freeSolo) {
            inputProps.value = controllerProps.field.value;
          }
          // Sometimes autocomplete input holds old value when we
          // focus and want to start typing. This check fixes it.
          else if (hasInputReceivedFirstClickAfterBlur) {
            inputProps.value = currentOption?.[titleKey as string];
          }
          // Workaround for case when we have current value, but Autocomplete does not shows it's value in input
          // We also don't want to block typing, so this value won't persist when we are focused on input
          else if (
            currentOption?.[titleKey as string] &&
            (!inputProps.value || inputProps.value !== currentOption?.[titleKey as string]) &&
            document.activeElement !== inputRef.current
          ) {
            inputProps.value = currentOption[titleKey as string];
          }

          return (
            <FormFieldBase
              {...props}
              {...params}
              required={required}
              inputProps={inputProps}
              InputProps={{ ...params.InputProps, readOnly: readonly }}
              name={controllerProps.field.name}
              inputRef={mergeMultipleRefs(controllerProps.field.ref, inputRef)}
            />
          );
        }}
        // incorrect type inference for popper component
        PopperComponent={renderPopperComponent as any}
      />
    );
  };

  return (
    <Controller
      render={renderAutocomplete}
      name={name}
      control={control}
      rules={{ validate, required }}
    />
  );
};

export default ComboBoxField;

import React from 'react';
import { useNumbersStyle } from '@/features/FinancialCabinet/Numbers/Numbers.styles';
import { INumberDiscountProps } from '@/features/FinancialCabinet/Numbers/Numbers.interfaces';

export const NumberDiscount = ({ discount }: INumberDiscountProps) => {
  const classes = useNumbersStyle();
  const postfix = '%';

  // формируем вывод информации
  return (
    <span className={classes.numberDiscount}>
      (-{discount}
      {postfix})
    </span>
  );
};

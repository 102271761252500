import { callTypes, HistoryDirection } from './HistoryPage.interfaces';

export const HISTORY_DEFAULT_ROW_HEIGHT = 80;
export const HISTORY_DATE_ROW_HEIGHT = 112;

export const formatTime = 'HH:mm';

export const DEFAULT_TIME_FROM = '00:00';
export const DEFAULT_TIME_TO = '23:59';

export const getCallType = (dir: string, isMissed: boolean): keyof typeof callTypes => {
  if ((dir === HistoryDirection.Out || dir === HistoryDirection.Internal) && !isMissed) {
    return 'Outgoing';
  }
  if (dir === HistoryDirection.Out && isMissed) {
    return 'OutgoingMissed';
  }
  if ((dir === HistoryDirection.In || dir === HistoryDirection.Internal) && isMissed) {
    return 'IncomingMissed';
  }
  return 'Incoming';
};

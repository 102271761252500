import { HistoryRowModel } from '@/client/generated/graphql';
import { findWeekDay, formatDate } from '@components/utils';
import { format } from 'date-fns';
import { getCallType, formatTime } from '../HistoryPage.constants';
import { callTypes, HistoryStatus } from '../HistoryPage.interfaces';

export const getRowsData = (data?: HistoryRowModel[] | null) =>
  data
    ? data.map((item) => {
        const start = new Date(item.date);
        const itemDay = start.getDay();
        const week = findWeekDay(itemDay);
        const { day, month, monthShort } = formatDate(start);

        return {
          id: item.id,
          uuid: item.uuid,
          call: callTypes[getCallType(item.direction, item.status !== HistoryStatus.Success)],
          employee: item.employee,
          employeeId: item.employeeId,
          employeeB: item.employeeB,
          employeeBId: item.employeeBId,
          employeeExt: item.employeeExt,
          employeeBExt: item.employeeBExt,
          departmentId: item.departmentId,
          other: item.other,
          via: item.via,
          client: item.client,
          start,
          date: {
            week,
            month,
            day,
            monthShort,
          },
          time: format(new Date(item.date), formatTime),
          wait: item.provision,
          duration: item.duration,
          record: item.record,
          fax: item.fax,
          transcription: item.transcription,
          isInBlackList: false,
          blackListComment: null,
        };
      })
    : [];

import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const usePhotoBlockStyles = makeStyles(
  ({ color: { secondary, tertiary } }) => ({
    photoBlock: {
      width: '64em',
      marginBottom: '2em',
    },
    photoBlockFirstColumn: {
      flex: '0 0 17em',

      marginRight: '1em',
    },
    photoPlaceholder: {
      height: pxToEm(196),
      backgroundColor: secondary[50],
      borderRadius: '16px',

      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    photoField: {
      marginTop: '1em',
    },
    photoFieldControl: {
      flexDirection: 'row',
    },
    photoBlockSecondColumn: {
      flex: '0 0 49em',
    },
    photoBlockAlert: {
      display: 'flex',
      flexDirection: 'column',
    },
    photoBlockAlertItem: {
      position: 'relative',
      marginBottom: '.2em',
      paddingLeft: '.8em',
      '&::before': {
        position: 'absolute',
        width: '3px',
        height: '3px',
        borderRadius: '100%',
        backgroundColor: tertiary[900],
        left: 0,
        top: '8px',
        content: '""',
      },
    },
  }),
  { index: 3 }
);

export const usePayerDataStyle = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexFlow: 'column',
    },
    payerDataRoot: {
      padding: '0 2em 6em',
    },
    body: {
      position: 'relative',
      display: 'flex',
      height: '100%',
    },
    documentsContentContainer: {
      borderRadius: '12px',
    },
    documentsContent: {
      paddingTop: '0',
    },
    personalDataTitle: {
      paddingBottom: '0.875em',
    },
    personalDataColumn: {
      width: '31em',
    },
    personalDataContainer: {
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '0.75em',
      padding: '1.5em',
      marginBottom: '2em',
    },
    personalDataItem: {
      paddingBottom: '1.5em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    personalDataContactTitle: {
      paddingBottom: '1em',
    },
    personalDataUploadLabel: {
      paddingBottom: '0.75em',
      display: 'block',
    },
    editContactsBtn: {
      height: '2em',
    },
    editContactsItem: {
      paddingBottom: '1em',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    editContactsContainer: {
      display: 'flex',
      alignItems: 'end',
    },
    personalDataRemoveBtn: {
      width: '2.875em',
      height: '2.5em',
      marginLeft: '1em',
      padding: 0,
    },
    editContactsRemoveBtn: {
      width: '2.875em',
      height: '2.5em',
      marginLeft: '1em',
      padding: 0,
    },
    addContactBtn: {
      width: '11em',
      height: '2em',
    },
    personalDataRadioContainer: {
      width: '13.75em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    personalDataGenderContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    personalDataGenderControls: {
      display: 'flex',
      marginTop: '.3em',
    },
    radioFormControlLabel: {
      marginBottom: 0,
      border: 0,
      '&:last-child': {
        marginLeft: pxToEm(46),
      },
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import Flex from '@shared/components/Flex';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DomainStatus,
  TariffChangeResultType,
  TariffInitStateType,
} from '@components/typings/interfaces';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { AlertCircleIcon, MinusIcon, PlusIcon } from '@shared/assets/images/icons';
import { toPrecision } from '@components/utils';
import { getCurrentClient, getCurrentDomain, getMonthRatio } from '@/utils';
import { Button } from '@shared/components/Button/Button';
import MessageDialog from '@shared/components/MessageDialog';
import { FormArrowSwitcher } from '@components/FormArrowSwitcher/FormArrowSwitcher';
import { MAX_ACCOUNT_AMOUNT } from '@/features/Documents/Contract/Contract.constants';
import BodyContainer from '@/layouts/BodyContainer';
import {
  getAccountsPrices,
  getComputedMinEmployeesTooltipText,
  getComputedMinIntegrationsTooltipText,
  getTariffExactOptions,
  useTariffProps,
} from '@/utils/tariff';
import {
  TariffDiscount,
  TariffIncludedOptionsList,
  TariffPriceDiscount,
  TariffTotalPrice,
} from '@components/TariffForm';
import SwitchField from '@shared/components/SwitchField';
import RadioGroup from '@shared/components/RadioGroup';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import { PreselectTariffType } from '@/features/Documents/Contract/Contract.interfaces';
import ControlButtons from '@shared/components/ControlButtons';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import Preloader from '@shared/components/Preloader';
import { OptionType, TariffOptionCategory } from '@/client/generated/graphql';
import { useTariffsStyle } from './Tariffs.styles';
import { useChangeTariffMethodsHooks } from './hooks/useChangeTariffMethods.hooks';

export const Tariffs: FunctionComponent = () => {
  const classes = useTariffsStyle();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const { watch, setValue } = formMethods;
  const accountAmount = Number(watch('accountAmount') || 0);
  const integrationsAmount = Number(watch('integrationAmount') || 0);
  const selectedOptions = watch('additionalOptions');
  const storeRecordsId = Number(watch('records') || 0);
  const [isTariffEdit, setTariffEdit] = useState(false);
  const [isTariffChangeDialogShow, setTariffChangeDialogShow] = useState(false);
  const [isBalanceLowDialogShow, setBalanceLowDialogShow] = useState(false);
  const [isReset, setFormReset] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [tariffInitState, setTariffInitState] = useState<TariffInitStateType | null>(null);
  const monthRatio = getMonthRatio();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { status } = getCurrentDomain(userData?.user) || {};
  const { isModerated } = getCurrentClient(userData?.user) || {};

  const {
    accountOption,
    additionalAccountOption,
    additionalOptionsKeyList,
    additionalTariffOptions,
    optionsResultList,
    optionsComputedPrices,
    employeeComputedPrices,
    currentTariff,
    currentEmployeeCount,
    currentTariffEmployeesCount,
    updateTariffData,
    isTariffRequested,
    tariffRequestData,
    tariffRequest,
    installedIntegrationsCount,
    nextMonthPrice,
    isTariffActive,
    isDataLoading,
    loadingSelectedTariff,
  } = useTariffProps(selectedOptions, accountAmount, integrationsAmount, storeRecordsId);

  const areRecordsStorageSettingsIndividual = additionalTariffOptions[OptionType.Records]?.some(
    (option) => typeof option?.domainTariffOptionParams?.days === 'number'
  );

  const {
    modifyTariff,
    loadingModifyTariff,
    createTariffChangeRequest,
    loadingCreateRequest,
    cancelTariffChangeRequest,
    loadingCancelRequest,
    balance,
    getBalanceData,
  } = useChangeTariffMethodsHooks();

  const getChangedOptionList = () => {
    if (tariffInitState && selectedOptions) {
      const { totalEmployeeCount } = getTariffExactOptions(currentTariff?.options || []);
      const { accounts, options } = tariffRequestData;
      const computedAccountAmount = accounts || accountAmount;
      const isAccountUnchanged = computedAccountAmount === totalEmployeeCount;
      const isDomainsLower = computedAccountAmount < totalEmployeeCount;
      const accountDomainDifference = isDomainsLower
        ? totalEmployeeCount - computedAccountAmount
        : computedAccountAmount - totalEmployeeCount;

      const opt = additionalOptionsKeyList.reduce(
        (result: TariffChangeResultType, optionName, optionIndex) => {
          const { isRequested, amount } = options[optionName] || {};
          const optionInitCount = tariffInitState[optionIndex].amount || 0;
          const isOptionBeenEnabled = tariffInitState[optionIndex].isSelected || false;
          const isOptionNowEnabled = isRequested ?? selectedOptions[optionIndex];
          const isOptionDowngrade = isOptionBeenEnabled && !isOptionNowEnabled;
          const isOptionPromote = !isOptionBeenEnabled && isOptionNowEnabled;
          let domainDifference = 0;
          let isDomainsChanged = false;
          let isOptionDomainsCountLess = false;
          let isOptionDomainsCountMore = false;

          if (optionName === OptionType.Integrations) {
            const amountInRequest = isRequested ? amount / accounts : null;
            const computedIntegrations =
              amountInRequest || (selectedOptions[optionIndex] ? integrationsAmount : 0);
            isOptionDomainsCountLess = computedIntegrations < optionInitCount;
            isOptionDomainsCountMore = computedIntegrations > optionInitCount;
            isDomainsChanged = computedIntegrations !== optionInitCount;
            if (isOptionDowngrade) {
              domainDifference = optionInitCount;
            } else if (isOptionPromote) {
              domainDifference = computedIntegrations;
            } else if (isDomainsChanged) {
              domainDifference = isOptionDomainsCountLess
                ? optionInitCount - computedIntegrations
                : computedIntegrations - optionInitCount;
            }
          }
          if (
            optionName === OptionType.Records &&
            !areRecordsStorageSettingsIndividual &&
            (isOptionNowEnabled || isOptionBeenEnabled)
          ) {
            const sOptions = additionalTariffOptions[OptionType.Records] || [];
            const sDaysCount =
              (tariffRequest
                ? sOptions.find(
                    (sOpt) => tariffRequest?.options?.some((req) => req.tariffOptionId === sOpt.id)
                  )?.params?.days
                : sOptions.find((sOpt) => sOpt.id === storeRecordsId)?.params?.days) || 0;
            isDomainsChanged = (optionInitCount || 0) !== (sDaysCount || 0);
            isOptionDomainsCountLess = sDaysCount < optionInitCount;
            isOptionDomainsCountMore = sDaysCount > optionInitCount;
          }
          return {
            ...result,
            [optionName]: {
              isPure: !isOptionDowngrade && !isOptionPromote && !isDomainsChanged,
              isDowngrade: isOptionDomainsCountLess || isOptionDowngrade,
              isPromote: isOptionDomainsCountMore || isOptionPromote,
              isSelected: isOptionNowEnabled,
              domainDifference,
            },
          };
        },
        {}
      );
      return {
        ...opt,
        [OptionType.Accounts]: {
          isPure: isAccountUnchanged,
          isDowngrade: isDomainsLower,
          isPromote: !isAccountUnchanged && !isDomainsLower,
          isSelected: true,
          domainDifference: accountDomainDifference,
        },
      } as TariffChangeResultType;
    }
    return {};
  };
  const changedOptionList = getChangedOptionList();
  const { downgradeList, promoteList } = Object.keys(changedOptionList).reduce(
    (res: { downgradeList: Array<string>; promoteList: Array<string> }, opt) => {
      if (changedOptionList[opt].isDowngrade) {
        res.downgradeList.push(opt);
      }
      if (changedOptionList[opt].isPromote) {
        res.promoteList.push(opt);
      }
      return res;
    },
    { downgradeList: [], promoteList: [] }
  );

  useEffect(() => {
    if (optionsResultList && !isReset) {
      const { accountMin } = optionsResultList[0] || {};
      if (currentTariff && currentTariff?.active) {
        const cTariffOptions = currentTariff?.options || [];
        const { baseEmployeeCount, additionalEmployeeCount, integrationCount } =
          getTariffExactOptions(cTariffOptions);
        const selectedAccountAmount = baseEmployeeCount + additionalEmployeeCount;
        const baseDomainsCount =
          selectedAccountAmount > currentEmployeeCount
            ? selectedAccountAmount
            : currentEmployeeCount;
        const integrationItemsCount = integrationCount / selectedAccountAmount;
        const { selectedTariffOptions, tariffInitStateValue } = additionalOptionsKeyList.reduce(
          (
            result: {
              selectedTariffOptions: Array<boolean>;
              tariffInitStateValue: TariffInitStateType;
            },
            optionKey
          ) => {
            let isSelected = false;
            let optionAmount = 0;
            if (optionKey === OptionType.Records && !areRecordsStorageSettingsIndividual) {
              const recordsOption = cTariffOptions.find(
                (opt) => additionalTariffOptions[OptionType.Records]?.find((o) => o.id === opt.id)
              );
              if (recordsOption) {
                result.selectedTariffOptions.push(true);
                result.tariffInitStateValue.push({
                  isSelected: !isSelected,
                  amount: recordsOption?.params?.days || 0,
                });
                setValue('records', String(recordsOption.id));
                return result;
              }
            }
            if (
              currentTariff?.options.find(
                (opt) => opt.type === optionKey && opt.category === TariffOptionCategory.Additional
              )
            ) {
              isSelected = true;
              optionAmount = 1;
              if (optionKey === OptionType.Integrations) {
                optionAmount = integrationItemsCount;
              }
            }
            result.tariffInitStateValue.push({
              isSelected,
              amount: optionAmount,
            });
            result.selectedTariffOptions.push(isSelected);
            return result;
          },
          { selectedTariffOptions: [], tariffInitStateValue: [] }
        );
        setValue('accountAmount', baseDomainsCount);
        setValue('additionalOptions', selectedTariffOptions);
        setValue('integrationAmount', integrationItemsCount);
        setTariffInitState(tariffInitStateValue);
        setFormReset(true);
      } else {
        setValue(
          'accountAmount',
          accountMin > currentEmployeeCount ? accountMin : currentEmployeeCount
        );
      }
    }
  }, [
    additionalOptionsKeyList,
    currentEmployeeCount,
    optionsResultList,
    setValue,
    currentTariff,
    isReset,
    installedIntegrationsCount,
    additionalTariffOptions,
    areRecordsStorageSettingsIndividual,
  ]);

  useEffect(() => {
    if (isTariffEdit && !storeRecordsId && !areRecordsStorageSettingsIndividual) {
      setValue('records', String(additionalTariffOptions[OptionType.Records][0].id));
    }
  }, [
    additionalTariffOptions,
    areRecordsStorageSettingsIndividual,
    isTariffEdit,
    setValue,
    storeRecordsId,
  ]);

  function handleTariffChangeReset() {
    setFormReset(false);
    setTariffEdit(false);
  }

  const handleOperationFinish = useCallback(() => {
    setTariffChangeDialogShow(false);
    setTariffEdit(false);
    setSuccess(true);
    updateTariffData();
    getBalanceData().then(() => {
      handleTariffChangeReset();
    });
  }, [getBalanceData, setTariffChangeDialogShow, updateTariffData]);

  function handleCloseTariffChange() {
    setTariffChangeDialogShow(false);
  }

  function handleCloseTariffBalance() {
    setBalanceLowDialogShow(false);
  }

  function getDetails(optionName: string) {
    const isAccounts = optionName === OptionType.Accounts;
    const option = additionalTariffOptions[optionName];
    if (optionName === OptionType.Records) {
      return option.find((i) => i.id === storeRecordsId);
    }
    return isAccounts ? accountOption : option[0];
  }

  function handleResetChangedOption(optionName: string, optionIndex: number) {
    if (optionName === OptionType.Accounts) {
      const { baseEmployeeCount, additionalEmployeeCount } = getTariffExactOptions(
        currentTariff?.options || []
      );
      setValue('accountAmount', baseEmployeeCount + additionalEmployeeCount);
    } else {
      const restoredSelectedValues = [...selectedOptions];
      const { isSelected = false, amount = 0 } = tariffInitState?.[optionIndex] || {};
      restoredSelectedValues[optionIndex] = isSelected;
      if (optionName === OptionType.Integrations) {
        setValue('integrationAmount', amount);
      }
      if (optionName === OptionType.Records && !areRecordsStorageSettingsIndividual) {
        const sOptions = additionalTariffOptions[OptionType.Records] || [];
        const recordsId = sOptions.find((sOpt) => sOpt.params.days === amount)?.id || 0;
        setValue('records', String(recordsId));
      }
      setValue('additionalOptions', restoredSelectedValues);
    }
  }

  function getPriceMultipliedByAccount(optionName: string, amount: number, justPer?: boolean) {
    const details = getDetails(optionName);

    const perEmployee = Boolean(details?.params?.perEmployee || false);
    const monthlyFee = details?.monthlyFee || 0;

    if (perEmployee) {
      let multiplier = 1;
      if (optionName === OptionType.Integrations) {
        const { domainDifference, isPure, isDowngrade } = changedOptionList[optionName] || {};
        multiplier = isPure ? integrationsAmount : domainDifference;
        if (isDowngrade) {
          multiplier = integrationsAmount + domainDifference;
        }
      }
      return monthlyFee * amount * multiplier * monthRatio;
    }
    if (justPer) return 0;
    return monthlyFee * monthRatio;
  }

  function getPriceWitchPerOptions() {
    const { domainDifference } = changedOptionList[OptionType.Accounts] || {};
    const { accountMax = 0 } = optionsResultList?.[0] || {};
    const { accountsOption, additionalAccountsOption } = getTariffExactOptions(
      currentTariff?.options || []
    );
    const { accountPrice, additionalAccountPrice } = getAccountsPrices(
      accountsOption,
      additionalAccountsOption,
      optionsResultList?.[0]
    );
    const isOnlyBase = accountAmount <= accountMax;
    const isOnlyAdditional = accountAmount - domainDifference > accountMax;

    const newAdditionalAccounts = Math.abs(accountMax - accountAmount);
    const newBaseAccounts = domainDifference - newAdditionalAccounts;

    const newDomainsTotalPrice = () => {
      if (isOnlyBase) return accountPrice * domainDifference;
      if (isOnlyAdditional) return additionalAccountPrice * domainDifference;

      return newBaseAccounts * accountPrice + newAdditionalAccounts * additionalAccountPrice;
    };

    return additionalOptionsKeyList.reduce(
      (res: number, optionName: string, optionIndex: number) => {
        const { isSelected } = tariffInitState?.[optionIndex] || {};
        const { isPure, isDowngrade } = changedOptionList[optionName] || {};
        if (isSelected && (isPure || isDowngrade)) {
          const price = getPriceMultipliedByAccount(optionName, domainDifference, true);
          return res + price;
        }
        return res;
      },
      newDomainsTotalPrice() * monthRatio
    );
  }

  const handleTariffChange = useCallback(
    (isTariffModify: boolean, isTariffRequest: boolean) => {
      if (loadingCreateRequest || loadingCancelRequest || loadingModifyTariff) return;

      const setTariffModifyData = (isModify: boolean, onlyPromoted?: boolean) => {
        const {
          id = 0,
          accountId = 0,
          additionalAccountId = 0,
          accountMax = 0,
        } = optionsResultList?.[0] || {};
        const accounts = onlyPromoted
          ? accountAmount + (changedOptionList[OptionType.Accounts]?.domainDifference || 0)
          : accountAmount;
        const baseAccounts = accounts <= accountMax ? accounts : accountMax;
        const additionalAccounts = accounts <= accountMax ? 0 : accounts - accountMax;

        const data: PreselectTariffType = {
          tariffId: id,
          options: [
            {
              tariffOptionId: accountId,
              count: baseAccounts,
            },
          ],
        };
        if (additionalAccounts > 0) {
          data.options.push({
            tariffOptionId: additionalAccountId,
            count: additionalAccounts,
          });
        }

        additionalOptionsKeyList.forEach((optionKey: string, optionIndex: number) => {
          const { isDowngrade: isAccountDowngrade, domainDifference: accountDomainDifference = 0 } =
            changedOptionList[OptionType.Accounts] || {};
          const { isSelected, amount = 1 } = tariffInitState?.[optionIndex] || {};
          const { isDowngrade } = changedOptionList[optionKey] || {};
          const isIntegrationOption = optionKey === OptionType.Integrations;
          const sIntegrationsAmount = isIntegrationOption ? accounts * integrationsAmount || 1 : 1;
          const sOption = additionalTariffOptions[optionKey];
          const optionCount = isIntegrationOption ? Number(sIntegrationsAmount) : accounts;
          let sOptionId = sOption[0].id;
          let isPerEmployee = sOption[0].params?.perEmployee || false;
          if (
            sOption.length > 1 &&
            optionKey === OptionType.Records &&
            !areRecordsStorageSettingsIndividual
          ) {
            const subOption = sOption.find((item) => item.id === storeRecordsId);
            sOptionId = subOption?.id || 0;
            isPerEmployee = subOption?.params?.perEmployee || false;
          }
          if (isModify && isSelected && isDowngrade) {
            const accountDomains = isAccountDowngrade
              ? accounts + accountDomainDifference
              : accounts;
            const optionAmount = isPerEmployee ? accountDomains : amount;
            data.options.push({
              tariffOptionId: sOptionId,
              count: isIntegrationOption ? amount * accountDomains : optionAmount,
            });
          } else if (selectedOptions[optionIndex]) {
            data.options.push({
              tariffOptionId: sOptionId,
              count: isPerEmployee ? optionCount : 1,
            });
          }
        });
        return {
          data,
        };
      };

      const tariffChangeError = () => {
        setTariffChangeDialogShow(false);
        setError(true);
      };

      if (isTariffModify && isTariffRequest) {
        // result.data.
        modifyTariff({
          variables: { ...setTariffModifyData(true, true) },
          refetchQueries: [
            {
              query: GET_SELECTED_TARIFF_QUERY,
            },
          ],
        })
          .then(() => {
            createTariffChangeRequest({ variables: setTariffModifyData(false) })
              .then(() => {
                handleOperationFinish();
              })
              .catch(() => {
                tariffChangeError();
              });
          })
          .catch(() => {
            tariffChangeError();
          });
      } else if (isTariffModify) {
        modifyTariff({
          variables: setTariffModifyData(true),
          refetchQueries: [
            {
              query: GET_SELECTED_TARIFF_QUERY,
            },
          ],
        })
          .then(() => {
            handleOperationFinish();
          })
          .catch(() => {
            tariffChangeError();
          });
      } else if (isTariffRequest) {
        createTariffChangeRequest({ variables: setTariffModifyData(false) })
          .then(() => {
            handleOperationFinish();
          })
          .catch(() => {
            tariffChangeError();
          });
      }
    },
    [
      loadingCreateRequest,
      loadingCancelRequest,
      loadingModifyTariff,
      optionsResultList,
      accountAmount,
      changedOptionList,
      additionalOptionsKeyList,
      tariffInitState,
      integrationsAmount,
      additionalTariffOptions,
      areRecordsStorageSettingsIndividual,
      selectedOptions,
      storeRecordsId,
      modifyTariff,
      createTariffChangeRequest,
      handleOperationFinish,
    ]
  );

  function getPriceToPay(arr: Array<string>) {
    if (!arr.length) return { priceToBuy: 0, balanceAfter: -1, isBalanceLow: false };
    const priceToBuy = arr.reduce((res: number, optionName: string) => {
      if (optionName === OptionType.Accounts) {
        const priceWithAccounts = getPriceWitchPerOptions();
        return res + priceWithAccounts;
      }
      const price = getPriceMultipliedByAccount(
        optionName,
        Math.max(accountAmount, currentTariffEmployeesCount)
      );
      return res + price;
    }, 0);
    const isBalanceLow = (balance || 0) - priceToBuy < 0;
    return {
      priceToBuy,
      balanceAfter: balance - priceToBuy,
      isBalanceLow,
    };
  }

  const { priceToBuy, balanceAfter, isBalanceLow } = getPriceToPay(promoteList);

  function handleTariffConfirmClick() {
    const isTariffPromoted = promoteList.length !== 0;
    const isTariffDowngraded = downgradeList.length !== 0;
    if (!isTariffPromoted && !isTariffDowngraded) {
      setTariffEdit(false);
      return;
    }
    if (isBalanceLow) {
      setBalanceLowDialogShow(true);
      return;
    }
    if (!isTariffChangeDialogShow && isTariffPromoted) {
      setTariffChangeDialogShow(true);
      return;
    }
    handleTariffChange(isTariffPromoted, isTariffDowngraded);
  }

  function handleTariffRequestCancel() {
    cancelTariffChangeRequest().then(() => {
      updateTariffData();
      getBalanceData();
    });
  }

  const renderPromotedPrice = (optionName: string) => {
    if (optionName === OptionType.Accounts) {
      return getPriceWitchPerOptions();
    }
    return getPriceMultipliedByAccount(
      optionName,
      Math.max(accountAmount, currentTariffEmployeesCount)
    );
  };

  const renderChangedOptionItem = (arr: Array<string>) => {
    if (!arr.length) return null;
    return arr.map((optionName) => {
      const { isDowngrade, isPromote } = changedOptionList[optionName] || {};
      const OptionIcon = isPromote ? (
        <PlusIcon className={classes.successIcon} />
      ) : (
        <MinusIcon className={classes.warningIcon} />
      );
      const optionChangeKey = isDowngrade ? 'OPTION_DOWNGRADE' : 'OPTION_PROMOTE';
      const details = getDetails(optionName);
      return (
        <Flex
          key={`${optionName}`}
          justifyContent={'spaceBetween'}
          className={classes.tariffChangedOptionLine}
        >
          <Flex alignItems={'center'}>
            {OptionIcon}
            <Typography type={'text3'} color={'tertiary900'}>
              {`${translate(optionChangeKey)} "${details?.name}"`}
            </Typography>
          </Flex>
          {isPromote ? (
            <Typography bold type={'text3'} color={'tertiary900'}>
              {`${toPrecision(renderPromotedPrice(optionName), false)} ₽`}
            </Typography>
          ) : null}
        </Flex>
      );
    });
  };

  const renderBalanceDetails = (arr: Array<string>) => {
    if (!arr.length) return null;

    return (
      <Flex direction={'column'} className={classes.tariffBalanceInformationLine}>
        <Flex justifyContent={'spaceBetween'} className={classes.tariffBalanceInformation}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('TARIFF_PAYMENT_PRICE')}
          </Typography>
          <Typography type={'text3'} color={'primary700'}>
            {`${toPrecision(priceToBuy, false)} ₽`}
          </Typography>
        </Flex>
        <Flex justifyContent={'spaceBetween'} className={classes.tariffBalanceInformation}>
          <Typography type={'text3'} color={'tertiary500'}>
            {translate('TARIFF_PAYMENT_BALANCE_AFTER')}
          </Typography>
          <Typography type={'text3'} color={'tertiary500'}>
            {`${toPrecision(balanceAfter, false)} ₽`}
          </Typography>
        </Flex>
        <Flex className={classes.tariffBalanceInformationDescription}>
          <AlertCircleIcon className={classes.infoIcon} />
          <Typography type={'text4'} color={'tertiary500'}>
            {translate('TARIFF_PAYMENT_BALANCE_NOTIFICATION')}
          </Typography>
        </Flex>
      </Flex>
    );
  };

  const renderTariffDialogContent = () => {
    if (currentTariff && optionsResultList) {
      return (
        <Flex direction={'column'} alignItems={'center'} justifyContent={'spaceBetween'}>
          <div className={clsx(classes.defaultElementWidthFull, classes.marginBottom2)}>
            {renderChangedOptionItem(downgradeList)}
            {renderChangedOptionItem(promoteList)}
            {renderBalanceDetails(promoteList)}
          </div>
          <ControlButtons
            confirmTitle={'CONFIRM'}
            cancelTitle={'CANCEL'}
            cancelVariant="secondary"
            loading={loadingModifyTariff}
            confirmDisable={loadingModifyTariff}
            onConfirmClick={handleTariffConfirmClick}
            onCancelClick={() => setTariffChangeDialogShow(false)}
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            small
          />
        </Flex>
      );
    }
    return null;
  };

  const renderTariffBalanceDialogContent = () => (
    <>
      <div className={classes.tariffMessageContent}>
        <Translate
          i18nKey={'TARIFF_LOW_BALANCE_MESSAGE'}
          components={{
            t: <Typography type={'text3'} color={'tertiary900'} />,
            a: <NavLink to={'/cabinet/balance'} />,
          }}
        />
      </div>
      <Flex justifyContent={'flexEnd'} alignItems={'center'}>
        <Button
          title={translate('CLOSE')}
          onClick={handleCloseTariffBalance}
          variant={'primary'}
          smallHeight
        />
      </Flex>
    </>
  );

  const renderDialogMessages = () => (
    <>
      <MessageDialog
        isOpen={isTariffChangeDialogShow}
        title={translate('TARIFF_CHANGE_TITLE')}
        onCancel={handleCloseTariffChange}
        contentClass={classes.defaultElementWidth32}
        bodyWrapperClass={classes.tariffDialogBodyWrapper}
        renderContent={renderTariffDialogContent()}
      />
      {isBalanceLowDialogShow ? (
        <MessageDialog
          isOpen={isBalanceLowDialogShow}
          title={translate('NUMBER_BOUGHT_TITLE_NO_MONEY')}
          onCancel={handleCloseTariffBalance}
          contentClass={classes.defaultElementWidth24}
          renderContent={renderTariffBalanceDialogContent()}
        />
      ) : null}
      {isError ? (
        <MessageDialog
          isOpen={isError}
          title={translate('SOMETHING_WENT_WRONG')}
          message={translate('TARIFF_CHANGE_UNKNOWN_ERROR')}
          onCancel={() => setError(false)}
          contentClass={classes.defaultElementWidth24}
          renderControls={
            <Button
              title={translate('CLOSE')}
              onClick={() => setError(false)}
              variant={'primary'}
              smallHeight
            />
          }
        />
      ) : null}
      {isSuccess ? (
        <MessageDialog
          isOpen={isSuccess}
          title={translate('TARIFF_CHANGE_TITLE_SIMPLE')}
          message={translate('TARIFF_CHANGE_SUCCESS')}
          onCancel={() => setSuccess(false)}
          contentClass={classes.defaultElementWidth24}
          renderControls={
            <Button
              title={translate('CLOSE')}
              onClick={() => setSuccess(false)}
              variant={'primary'}
              smallHeight
            />
          }
        />
      ) : null}
    </>
  );

  const renderWarningMessage = (message: string) => (
    <Flex className={classes.tariffOptionWarning}>
      <AlertCircleIcon className={classes.warningIcon} />
      <Typography type={'text4'} color={'tertiary600'}>
        {message}
      </Typography>
    </Flex>
  );

  const renderOptionChangeMessage = (optionName: string, optionIndex: number) => {
    if (tariffInitState) {
      let warningMessage = '';

      const getInstallKey = (isDowngrade: boolean) =>
        isDowngrade ? 'OPTION_UNINSTALL_MESSAGE' : 'OPTION_INSTALL_MESSAGE';

      const messageType = (domainDiff: number, type: OptionType, isDowngrade: boolean) => {
        const isAccounts = type === OptionType.Accounts;
        if (domainDiff === 1) {
          const verbDirection = () => {
            if (isDowngrade) {
              return isAccounts ? 'OPTION_DISABLED' : 'OPTION_DISABLED_F';
            }
            return isAccounts ? 'OPTION_ENABLED' : 'OPTION_ENABLED_F';
          };
          return {
            verb: translate(verbDirection()).toLowerCase(),
            type: translate(isAccounts ? 'EMPLOYEE_AMOUNT_1' : 'INTEGRATION').toLowerCase(),
          };
        }
        const verbMessage = translate(
          isDowngrade ? 'OPTION_DISABLED_MID' : 'OPTION_ENABLED_MID'
        ).toLowerCase();
        if (domainDiff > 1 && domainDiff < 5) {
          return {
            verb: verbMessage,
            type: translate(isAccounts ? 'EMPLOYEE_AMOUNT_2' : 'INTEGRATIONS').toLowerCase(),
          };
        }
        return {
          verb: verbMessage,
          type: translate(isAccounts ? 'EMPLOYEE_AMOUNT_5' : 'LOT_OF_INTEGRATIONS').toLowerCase(),
        };
      };

      if (optionName === OptionType.Accounts) {
        const { isPure, isDowngrade, domainDifference } = changedOptionList?.[optionName] || {};
        const { verb, type } = messageType(domainDifference, optionName, isDowngrade);
        if (isPure) return null;
        const warningTranslateKey = getInstallKey(isDowngrade);
        warningMessage = translate(warningTranslateKey, {
          verb,
          amount: domainDifference,
          type,
        });
      } else {
        const { isPure, isDowngrade, isPromote, domainDifference, isSelected } =
          changedOptionList?.[optionName] || {};

        if (isPure) return null;

        if (optionName === OptionType.Integrations && isSelected) {
          const warningTranslateKey = getInstallKey(isDowngrade);
          const { verb, type } = messageType(domainDifference, optionName, isDowngrade);

          warningMessage = translate(warningTranslateKey, {
            verb,
            amount: domainDifference,
            type,
          });
        } else {
          if (isDowngrade) {
            if (
              optionName === OptionType.Records &&
              !areRecordsStorageSettingsIndividual &&
              isSelected
            ) {
              warningMessage = translate('OPTION_CHANGE_SIMPLE');
            } else {
              warningMessage = translate('OPTION_UNINSTALL_SIMPLE');
            }
          }
          if (isPromote) {
            warningMessage = translate('OPTION_INSTALL_SIMPLE');
          }
        }
      }

      return (
        <Flex className={classes.tariffOptionWarningWrapper}>
          {renderWarningMessage(warningMessage)}
          {!isTariffRequested ? (
            <Button
              onClick={() => handleResetChangedOption(optionName, optionIndex)}
              variant={'text'}
            >
              <Typography underline type={'text4'} color={'link600'}>
                {translate('CANCEL')}
              </Typography>
            </Button>
          ) : null}
        </Flex>
      );
    }
    return null;
  };

  const renderDiscountLossWarning = () => {
    const { isDowngrade, domainDifference } = changedOptionList?.[OptionType.Accounts] || {};
    const { baseEmployeeCount, additionalEmployeeCount, discountLimit } = getTariffExactOptions(
      currentTariff?.options || []
    );

    const computedAccountAmount = baseEmployeeCount + additionalEmployeeCount - domainDifference;

    if (computedAccountAmount < discountLimit && isDowngrade) {
      return (
        <Flex className={classes.tariffOptionWarningWrapper}>
          {renderWarningMessage(
            translate('OPTION_DISCOUNT_LOSS_MESSAGE', { amount: discountLimit })
          )}
        </Flex>
      );
    }
    return null;
  };

  const renderOptionDescription = (optionKey: string, keyIndex: number) => {
    const oList = additionalTariffOptions[optionKey];
    const isOptionSelected = selectedOptions?.[keyIndex];
    let currentTariffOption = currentTariff?.options.find(
      (opt) => opt.type === optionKey && opt.category === TariffOptionCategory.Additional
    );
    if (oList.length > 1 && optionKey === OptionType.Records) {
      if (areRecordsStorageSettingsIndividual) {
        return (
          <TooltipHover
            tooltipAdditionalClass={classes.tooltipRecordRetentionInfo}
            contentClass={classes.textAlignLeft}
            title={
              <Typography pxToEmSize={12}>
                {translate(
                  'RECORD_RETENTION_PERIOD_IS_SPECIFIED_IN_THE_SETTINGS_IN_THE_CONVERSATION_RECORDING_SECTION'
                )}
              </Typography>
            }
            placement="right"
          >
            <Flex direction="column">
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate(optionKey)}
              </Typography>
              <Typography className={classes.marginTop025} pxToEmSize={12} color={'tertiary600'}>
                {translate('INDIVIDUAL')}
              </Typography>
            </Flex>
          </TooltipHover>
        );
      }
      return (
        <>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate(optionKey)}
          </Typography>
          <RadioGroup defaultValue={isTariffEdit ? String(oList[0].id) : ''} name={'records'}>
            <Flex direction={'row'}>
              {oList.map((cOption, cIndex) => {
                currentTariffOption = currentTariff?.options.find((opt) => opt.id === cOption.id);
                return (
                  <Flex
                    className={classes.tariffMultipleOption}
                    direction={'column'}
                    key={`${cOption.type}-${cIndex}`}
                  >
                    {cOption.id === currentTariffOption?.id || isTariffEdit ? (
                      <FormControlLabel
                        value={String(cOption.id)}
                        control={
                          <Radio
                            disabled={!isOptionSelected || !isTariffEdit}
                            color={'secondary'}
                          />
                        }
                        label={translate(`RECORDS_${cOption.params.days}`)}
                      />
                    ) : (
                      <Typography
                        type={'text3'}
                        color={'tertiary600'}
                        className={classes.paddingUpDown025}
                      >
                        {translate(`RECORDS_${cOption?.params?.days}`)}
                      </Typography>
                    )}
                    <Typography
                      className={clsx(
                        classes.tariffAdditionalTextPadding,
                        (cOption.id === currentTariffOption?.id || isTariffEdit) &&
                          classes.recordsStoringOptionLabelText
                      )}
                      type={'text4'}
                      color={'tertiary600'}
                    >
                      {`${translate('COST_PER_EMPLOYEE_OPTION', {
                        amount: cOption.baseMonthlyFee,
                      })}`}
                      <TariffDiscount discountData={currentTariffOption || cOption} />
                    </Typography>
                  </Flex>
                );
              })}
            </Flex>
          </RadioGroup>
        </>
      );
    }
    return (
      <>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {oList[0].name}
        </Typography>
        <div className={classes.tariffAdditionalOptionDescription}>
          <Typography
            className={classes.tariffAdditionalTextPadding}
            type={'text4'}
            color={'tertiary600'}
          >
            {`${translate(
              oList[0].params?.perEmployee ? 'COST_PER_EMPLOYEE_OPTION' : 'RUB_PER_MONTH',
              {
                amount: oList[0].baseMonthlyFee,
                additional: optionKey === OptionType.Integrations ? translate('PER_CRM') : '',
              }
            )}`}
            <TariffDiscount discountData={currentTariffOption || oList[0]} />
          </Typography>
        </div>
      </>
    );
  };

  const renderActionCell = (optionKey: string, keyIndex: number) => {
    const isOptionSelected = selectedOptions?.[keyIndex];
    const isPerEmployee = additionalTariffOptions[optionKey][0]?.params?.perEmployee || false;
    if (optionKey === OptionType.Integrations) {
      const { integrationMinMessage, computedMinIntegrations } =
        getComputedMinIntegrationsTooltipText(
          integrationsAmount,
          installedIntegrationsCount,
          translate
        );

      return (
        <>
          <Flex
            fullWidth
            justifyContent={'spaceBetween'}
            alignItems={'center'}
            className={!isOptionSelected || !isTariffEdit ? classes.tariffHiddenOption : ''}
          >
            <Typography type={'text4'} color={'tertiary600'}>
              {translate('INTEGRATIONS_COUNT')}
            </Typography>
            <FormArrowSwitcher
              minTooltip={integrationMinMessage}
              disabled={!isOptionSelected}
              min={
                isTariffEdit && isOptionSelected
                  ? computedMinIntegrations || 1
                  : computedMinIntegrations
              }
              max={MAX_ACCOUNT_AMOUNT}
              name={'integrationAmount'}
              defaultValue={1}
              rootClass={classes.defaultElementWidth7}
            />
          </Flex>
          <Typography
            className={isTariffEdit || !integrationsAmount ? classes.tariffHiddenOption : ''}
            type={'text4'}
            color={'tertiary600'}
          >
            {translate('INTEGRATIONS_AMOUNT', { amount: integrationsAmount })}
          </Typography>
        </>
      );
    }
    if (isOptionSelected && isPerEmployee) {
      return (
        <Typography type={'text4'} color={'tertiary600'}>
          {translate('PER_EMPLOYEE', { amount: accountAmount })}
        </Typography>
      );
    }
    return null;
  };

  const renderPrice = (optionKey: string, keyIndex: number) => {
    const isOptionSelected = selectedOptions?.[keyIndex];
    if (!isOptionSelected && !isTariffEdit) {
      return (
        <Typography type={'text4'} color={'tertiary600'}>
          {translate('OPTION_NOT_ENABLED')}
        </Typography>
      );
    }

    if (!isOptionSelected) return null;

    const price = optionsComputedPrices.price[keyIndex];
    const discountPrice = optionsComputedPrices.discountPrice[keyIndex];

    return <TariffPriceDiscount price={price} discountPrice={discountPrice} />;
  };

  const renderSwitchField = (optionKey: string, keyIndex: number) => {
    if (optionKey === OptionType.Integrations && installedIntegrationsCount) {
      return (
        <TooltipHover
          placement={'bottom'}
          title={
            <Typography className={classes.tooltip} color={'tertiary900'} type={'text4'}>
              {translate('TARIFF_INTEGRATION_SWITCH_OFF_DISABLED')}
            </Typography>
          }
        >
          <SwitchField disabled name={`additionalOptions[${keyIndex}]`} />
        </TooltipHover>
      );
    }
    if (optionKey === OptionType.Records && areRecordsStorageSettingsIndividual) {
      return <SwitchField disabled checked />;
    }
    return <SwitchField name={`additionalOptions[${keyIndex}]`} />;
  };

  const renderAdditionalTariffOptions = () => {
    if (!optionsResultList) return null;

    const { accountMin, accountPrice, additionalAccountPrice, accountMax } =
      optionsResultList[0] || {};
    const { price: employeePrice, discountPrice: employeeDiscountPrice } = employeeComputedPrices;

    const { accountsOption: cAccountOption, additionalAccountsOption: cAdditionalAccountsOption } =
      getTariffExactOptions(currentTariff?.options || []);

    const { accountMinMessage, computedMinAccount } = getComputedMinEmployeesTooltipText({
      accountAmount,
      currentEmployeeCount,
      accountOption: cAccountOption,
      translate,
      skipDiscount: true,
    });

    return (
      <>
        <div className={classes.tariffTitle}>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('ADDITIONAL_FUNCTIONAL')}
          </Typography>
        </div>
        <Flex direction={'column'} className={classes.tariffAdditionalFunctionalWrapper}>
          <Flex alignItems={'flexStart'} className={classes.tariffAdditionalOption}>
            <div
              className={clsx(classes.tariffOptionSwitchCell, {
                [classes.tariffHiddenOption]: !isTariffEdit,
              })}
            />
            <Flex
              direction={'column'}
              alignItems={'flexStart'}
              className={classes.tariffOptionInfoCell}
            >
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate('EMPLOYEES')}
              </Typography>
              <div className={classes.tariffAdditionalOptionDescription}>
                <Typography
                  className={classes.tariffAdditionalTextPadding}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('COST_PER_EMPLOYEE', {
                    amount: accountPrice,
                  })}`}
                  <TariffDiscount discountData={cAccountOption || accountOption} />
                </Typography>
                <Typography
                  className={classes.tariffAdditionalTextPadding}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('DISCOUNT_COST_PER_EMPLOYEE', {
                    amount: accountMax + 1,
                    price: additionalAccountPrice,
                  })}`}
                  <TariffDiscount
                    discountData={cAdditionalAccountsOption || additionalAccountOption}
                  />
                </Typography>
              </div>
              {renderOptionChangeMessage(OptionType.Accounts, -1)}
              {renderDiscountLossWarning()}
            </Flex>
            <Flex
              justifyContent={'flexEnd'}
              alignItems={'center'}
              className={classes.tariffOptionActionCell}
            >
              <Flex
                justifyContent={'spaceBetween'}
                alignItems={'center'}
                className={clsx(classes.tariffOptionActionCellWrapper)}
              >
                {!isTariffEdit ? (
                  <Typography type={'text4'} color={'tertiary600'}>
                    {translate('PER_EMPLOYEE', { amount: accountAmount })}
                  </Typography>
                ) : (
                  <Typography type={'text4'} color={'tertiary600'}>
                    {`${translate('EMPLOYEE_MIN_COUNT', {
                      amount: accountMin,
                    })}`}
                  </Typography>
                )}
                <FormArrowSwitcher
                  minTooltip={accountMinMessage}
                  min={computedMinAccount}
                  max={MAX_ACCOUNT_AMOUNT}
                  name={'accountAmount'}
                  defaultValue={accountMin}
                  rootClass={clsx(classes.defaultElementWidth7, {
                    [classes.tariffHiddenOption]: !isTariffEdit,
                  })}
                />
              </Flex>
            </Flex>
            <Flex
              justifyContent={'flexEnd'}
              alignItems={'center'}
              className={classes.tariffOptionPriceCell}
            >
              <TariffPriceDiscount price={employeePrice} discountPrice={employeeDiscountPrice} />
            </Flex>
          </Flex>
          {additionalOptionsKeyList.map((optionKey: string, keyIndex: number) => (
            <Flex
              key={`${optionKey}-${keyIndex}`}
              alignItems={'flexStart'}
              className={classes.tariffAdditionalOption}
            >
              <Flex
                alignItems={'flexStart'}
                className={clsx(classes.tariffOptionSwitchCell, {
                  [classes.tariffHiddenOption]: !isTariffEdit,
                })}
              >
                {renderSwitchField(optionKey, keyIndex)}
              </Flex>
              <Flex
                direction={'column'}
                alignItems={'flexStart'}
                className={classes.tariffOptionInfoCell}
              >
                {renderOptionDescription(optionKey, keyIndex)}
                {renderOptionChangeMessage(optionKey, keyIndex)}
              </Flex>
              <Flex
                justifyContent={'flexEnd'}
                alignItems={'center'}
                className={classes.tariffOptionActionCell}
              >
                <Flex
                  justifyContent={'spaceBetween'}
                  className={classes.tariffOptionActionCellWrapper}
                >
                  {renderActionCell(optionKey, keyIndex)}
                </Flex>
              </Flex>
              <Flex
                justifyContent={'flexEnd'}
                alignItems={'center'}
                className={classes.tariffOptionPriceCell}
              >
                {renderPrice(optionKey, keyIndex)}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </>
    );
  };

  const renderTariffActions = () => {
    if (isTariffRequested) {
      return (
        <Button
          className={clsx(classes.tariffButton, classes.defaultElementWidth9)}
          title={translate('NUMBER_UNRESERVED_BUTTON')}
          onClick={handleTariffRequestCancel}
          variant={'primary'}
        />
      );
    }
    if (isTariffEdit) {
      return (
        <>
          <Button onClick={handleTariffChangeReset} title={translate('CANCEL')} variant={'text'} />
          <Button
            className={clsx(classes.tariffButton, classes.defaultElementWidth12)}
            title={translate('CONFIRM')}
            onClick={handleTariffConfirmClick}
            variant={'primary'}
          />
        </>
      );
    }
    if (status !== DomainStatus.Commercial) {
      return null;
    }
    if (!isTariffActive) {
      return null;
    }
    return (
      <Button
        className={clsx(classes.tariffButton, classes.defaultElementWidth12)}
        onClick={() => setTariffEdit(true)}
        title={translate('CHANGE')}
        disabled={isModerated === false}
        variant={'primary'}
      />
    );
  };

  return (
    <BodyContainer>
      <div className={classes.tariffContent}>
        <FormProvider {...formMethods}>
          <form>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loadingSelectedTariff ? (
              <Flex className={classes.marginTop3} justifyContent={'center'}>
                <Preloader size={'large'} />
              </Flex>
            ) : !isTariffActive && (currentTariff?.id || 0) <= 10 && !isDataLoading ? (
              <div className={classes.tariffArchiveWrapper}>
                <AlertCircleIcon className={classes.alertIcon} />
                <Typography
                  className={classes.marginTop2}
                  type={'text3'}
                  color={'tertiary900'}
                  medium
                >
                  {translate('ARCHIVE_TARIFF_WARNING_01')}
                </Typography>
                <Typography className={classes.marginTop2} type={'text3'} color={'tertiary900'}>
                  {translate('ARCHIVE_TARIFF_WARNING_02')}
                </Typography>
              </div>
            ) : (
              <>
                {!isTariffActive && (
                  <div className={classes.tariffArchiveWarningWrapper}>
                    <Flex direction={'row'}>
                      <AlertCircleIcon className={classes.alertIconSmall} />
                      <Typography
                        className={clsx(classes.marginTop05, classes.marginLeft1)}
                        type={'text3'}
                        color={'tertiary900'}
                        medium
                      >
                        {translate('ARCHIVE_TARIFF_WARNING_01')}
                      </Typography>
                    </Flex>
                    <Typography
                      className={clsx(classes.marginTop05, classes.marginBottom1)}
                      type={'text3'}
                      color={'tertiary900'}
                    >
                      {translate('ARCHIVE_TARIFF_WARNING_03')}
                    </Typography>
                  </div>
                )}
                <TariffIncludedOptionsList optionsList={optionsResultList} />
                {renderAdditionalTariffOptions()}
                <TariffTotalPrice
                  comparePrice={isTariffEdit}
                  optionsList={optionsResultList}
                  calcEmployeePrice={employeeComputedPrices}
                  optionsComputedPrices={optionsComputedPrices}
                  nextMonthPrice={nextMonthPrice}
                />
                <Flex justifyContent={'flexEnd'} className={classes.tariffActionsWrapper}>
                  {renderTariffActions()}
                </Flex>
                {renderDialogMessages()}
              </>
            )}
          </form>
        </FormProvider>
      </div>
    </BodyContainer>
  );
};

export default Tariffs;

import { DomainTelNumbersQuery } from '@/client/generated/graphql';
import { NumberCategory } from '@components/typings/interfaces';

export interface IDrawerNumbersProps {
  open: boolean;
  onClose(): void;
}

export const NumberTypeList = [
  {
    id: NumberCategory.Classic,
    name: 'NUMBER_Classic',
  },
  {
    id: NumberCategory.Beautiful,
    name: 'NUMBER_Beautiful',
  },
  {
    id: NumberCategory.Vip,
    name: 'NUMBER_Vip',
  },
  {
    id: NumberCategory.VipPlus,
    name: 'NUMBER_VipPlus',
  },
];

export type DomainTelNumber = Exclude<
  DomainTelNumbersQuery['getNumbers'][number],
  { __typename: 'FmcNumberModel' }
>;

import { uploadFieldsList } from '@components/utils';

export const personalDataFieldList = [
  'companyName',
  'gender',
  'inn',
  'kpp',
  'ogrn',
  'legalAddress',
  'postalAddress',
  'bankName',
  'bik',
  'correspondentAccount',
  'account',
  'bankAddress',
  'person',
  'series',
  'number',
  'departmentCode',
  'placeOfIssue',
  'dateOfIssue',
  'genitiveCasePerson',
  'position',
  'actBasis',
  'phone',
  'email',
  ...uploadFieldsList,
] as const;

export const jpegMimeString = 'image/jpeg';

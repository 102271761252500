import React, { FunctionComponent } from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import Typography from '@shared/components/Typography';
import { NumberDiscount } from '@/features/FinancialCabinet/Numbers/Numbers.helpers';
import { ICellProps } from '@components/LazyTable';
import { DomainNumber } from '../Numbers.interfaces';

export const NumberCostCell: FunctionComponent<ICellProps<DomainNumber>> = ({ item }) => {
  const { setupFee, setupDiscount, isSetupDiscountAbsolute } = item?.tariff || {};

  return (
    <Typography type={'text3'} color={'inherit'}>
      {`${setupFee ? toPrecision(setupFee, false) : '-'} ₽`}
      {!!setupDiscount && (
        <NumberDiscount discount={setupDiscount} isAbsolute={!!isSetupDiscountAbsolute} />
      )}
    </Typography>
  );
};

import { ListSubheader, SelectProps as MuiSelectProps } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { CheckIcon, ChevronDownIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormFieldBase from '../FormFieldBase';
import { GroupData, ISelectFieldProps } from './SelectField.interfaces';
import {
  useListSubheaderStyles,
  useSelectFieldOptionStyles,
  useSelectFieldStyles,
} from './SelectField.styles';

export const SelectField = <
  ValueKey extends keyof T,
  TitleKey extends keyof T,
  T = Record<string, unknown>,
>({
  name = '',
  valueKey,
  titleKey,
  data = [],
  defaultValue = '',
  label = '',
  InputProps,
  optionsClassName,
  SelectProps = {},
  disabledOptions = [],
  translating,
  required,
  Renderer,
  value,
  size,
  onChange,
  validate,
  disabled,
  placeholder,
  groups,
  color = 'primary',
  menuPaperClassName,
  iconClassName,
  ...props
}: ISelectFieldProps<ValueKey, TitleKey, T>) => {
  const { control, watch } = useFormContext() || {};
  const formValue = watch ? watch(name) : undefined;
  const defaultControlValue = formValue ?? defaultValue ?? '';
  const [translate] = useTranslation();
  const classes = useSelectFieldStyles();
  const optionClasses = useSelectFieldOptionStyles();
  const listSubheaderClasses = useListSubheaderStyles();

  const definedTitleKey = titleKey === undefined ? 'fallback' : titleKey;
  const fallbackTitleValue = placeholder || translate('CHOOSE') || '';
  const fallbackRenderData = { [definedTitleKey]: fallbackTitleValue } as T;

  const renderControllerValue = (cValue: string | number | undefined) => {
    if (cValue === '' || cValue === undefined) {
      return defaultControlValue;
    }
    return value || cValue;
  };

  const renderValue = (currentValue: unknown) => {
    const isCurrentValueValid =
      currentValue !== null && currentValue !== undefined && currentValue !== '';

    const currentData = !isCurrentValueValid
      ? fallbackRenderData
      : data.find((item) => item?.[valueKey] === currentValue) || fallbackRenderData;

    const title = String(currentData[definedTitleKey as TitleKey] ?? '');

    const titleTranslated = translating ? translate(title as string) : title;

    const renderContent = () => {
      if (Renderer) {
        return <Renderer content data={currentData} />;
      }

      return (
        <Typography type={'text3'} color={!isCurrentValueValid ? 'tertiary400' : 'inherit'}>
          {titleTranslated}
        </Typography>
      );
    };

    return (
      <MenuItem
        classes={{
          root: clsx(optionClasses.root, optionsClassName, {
            [optionClasses.sizeSmall]: size === 'small',
            [optionClasses.rootPrimary]: color === 'primary',
            [optionClasses.rootSecondary]: color === 'secondary',
            [optionClasses.customRoot]: Renderer,
            [optionClasses.disabledOption]: disabled,
          }),
        }}
        selected={false}
        disabled={false}
        value={currentValue as string | number}
      >
        {renderContent()}
      </MenuItem>
    );
  };

  const renderOption = (item: T) => {
    const optionValue = item[valueKey] ?? '';
    const itemDisabled = disabledOptions.length
      ? disabledOptions.filter((i) => i === optionValue).length > 0
      : false;
    const titleValue = titleKey !== undefined ? item[titleKey] : '';
    const selected = item[valueKey] === defaultControlValue;
    const text = () => {
      if (titleKey) {
        if (translating) {
          return translate(titleValue as string);
        }
        return titleValue;
      }
      return optionValue;
    };
    const renderGlyph = () => {
      if (!selected) {
        return null;
      }
      return (
        <CheckIcon
          className={clsx(optionClasses.glyph, {
            [optionClasses.glyphPrimary]: color === 'primary',
            [optionClasses.glyphSecondary]: color === 'secondary',
          })}
        />
      );
    };

    const renderContent = () => {
      if (Renderer) {
        return <Renderer data={item} selected={selected} disabled={itemDisabled} />;
      }

      return (
        <>
          {renderGlyph()}
          <Typography
            className={clsx(!selected && optionClasses.textMarginLeft)}
            type={'text3'}
            color={'inherit'}
          >
            {`${text()}`}
          </Typography>
        </>
      );
    };

    return (
      <MenuItem
        key={`${optionValue}`}
        classes={{
          root: clsx(optionClasses.root, optionsClassName, {
            [optionClasses.rootPrimary]: color === 'primary',
            [optionClasses.rootSecondary]: color === 'secondary',
            [optionClasses.customRoot]: Renderer,
          }),
          selected: optionClasses.selected,
        }}
        selected={selected}
        disabled={itemDisabled}
        value={optionValue as string | number}
      >
        {renderContent()}
      </MenuItem>
    );
  };

  const renderChevron = () => (
    <div className={clsx(classes.chevron, iconClassName)}>
      <ChevronDownIcon />
    </div>
  );

  const formGroupsData: () => GroupData<T>[] | null = () => {
    if (!groups || groups.length === 0) {
      return null;
    }

    const groupsData: GroupData<T>[] = groups.map((g) => ({ ...g }));

    for (let i = 0; i < data.length; i += 1) {
      const dataItem = data[i]!;
      let groupIndexForItem = groupsData.findIndex(
        (group) => dataItem[group.dataKeyToGroupBy] === group.groupName
      );
      if (groupIndexForItem === -1) {
        groupIndexForItem = groupsData.length - 1;
      }

      const groupItem = groupsData[groupIndexForItem];
      if (!groupItem) {
        continue;
      }

      if (!groupItem.options) {
        groupItem.options = [];
      }
      groupItem.options.push(dataItem);
    }

    return groupsData;
  };

  const renderGroupData = (groupsData: GroupData<T>[]) =>
    groupsData.map(({ groupName, hideTitle, options, titleCode }, index) => {
      const reactNodes: React.ReactNode[] = [];
      if (!hideTitle) {
        const subheaderText = String(titleCode ? translate(titleCode) : groupName);

        reactNodes.push(
          <ListSubheader
            component="div"
            // Event with below line, MUI still gives `role="option"`, which is incorrect
            role="none"
            key={`${groupName}-${index}`}
            classes={listSubheaderClasses}
            onClickCapture={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {subheaderText}
          </ListSubheader>
        );
      }

      if (options) {
        reactNodes.push(...options.map((o) => renderOption(o)));
      }

      return reactNodes;
    });

  const renderOptions = () => {
    if (groups) {
      const groupsData = formGroupsData();
      if (groupsData !== null) {
        return renderGroupData(groupsData);
      }
    }

    return data.map((i) => renderOption(i));
  };

  const inputProps = {
    name,
    ...(InputProps || {}),
    classes: {
      ...(InputProps?.classes || {}),
      root: clsx(classes.inputRoot, InputProps?.classes?.root),
    },
  };

  const selectProps: Partial<MuiSelectProps> = {
    name,
    required,
    // disableUnderline: true,
    classes: { outlined: classes.outlined, select: classes.select },
    displayEmpty: true,
    renderValue,
    IconComponent: renderChevron,
    inputProps: { name },
    MenuProps: {
      MenuListProps: {
        disablePadding: true,
        classes: {},
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      classes: { paper: clsx(classes.paper, menuPaperClassName) },
    },
    ...SelectProps,
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate, required }}
      render={({
        field: { onChange: controllerOnChange, value: controllerValue, name: controllerName, ref },
      }) => (
        <FormFieldBase
          {...props}
          color={color}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (typeof onChange === 'function') {
              onChange(event);
            }
            if (typeof controllerOnChange === 'function') {
              controllerOnChange(event);
            }
          }}
          value={renderControllerValue(controllerValue)}
          size={size}
          select
          name={controllerName}
          disabled={disabled}
          label={label}
          defaultValue={defaultControlValue}
          InputProps={inputProps}
          SelectProps={selectProps}
          inputRef={ref}
        >
          {renderOptions()}
        </FormFieldBase>
      )}
      defaultValue={defaultControlValue}
    />
  );
};

export default SelectField;

import React, { FunctionComponent } from 'react';
import MessageDialog from '@shared/components/MessageDialog';
import Typography from '@shared/components/Typography';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import Translate from '@shared/components/Translate';
import { NavLink } from 'react-router-dom';
import { toPrecision } from '@components/utils/toPrecision';
import ControlButtons from '@shared/components/ControlButtons';
import { useDialogPayForNumberStyle } from './DialogPayForNumber.styles';
import { IDialogPayForNumberProps } from './DialogPayForNumber.interfaces';

const DialogPayForNumber: FunctionComponent<IDialogPayForNumberProps> = ({
  balance,
  totalNumberCost,
  onCancel,
  onConfirm,
  loading,
  ...modalProps
}) => {
  const classes = useDialogPayForNumberStyle();
  const [translate] = useTranslation();
  const canPay = balance >= totalNumberCost;

  function getCurrentTitle() {
    return canPay ? translate('NUMBER_BOUGHT_TITLE') : translate('NUMBER_BOUGHT_TITLE_NO_MONEY');
  }

  const renderDialogMessage = () => {
    if (canPay) {
      const restCost = balance - totalNumberCost;
      return (
        <Translate
          i18nKey="NUMBER_BOUGHT_BODY"
          values={{
            totalCost: `${toPrecision(totalNumberCost)} ₽`,
            restCost: `${toPrecision(restCost)} ₽`,
          }}
          components={{
            t: <Typography type={'text3'} color={'tertiary900'} />,
            b: <Typography type={'text2'} color={'primary700'} medium />,
          }}
        />
      );
    }
    return (
      <Translate
        i18nKey="NUMBER_BOUGHT_BODY_NO_MONEY"
        components={{
          t: <Typography type={'text3'} color={'tertiary900'} />,
          a: <NavLink to={'/cabinet/balance'} />,
        }}
      />
    );
  };

  const renderDialogActions = () => {
    if (canPay && onCancel) {
      return (
        <ControlButtons
          confirmTitle={'CONFIRM'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          onConfirmClick={onConfirm}
          onCancelClick={onCancel}
          loading={loading}
          justifyContent={'start'}
          flexDirection={'row-reverse'}
          small
        />
      );
    }
    return <Button title={translate('CLOSE')} onClick={onCancel} />;
  };

  const renderDialogContent = () => (
    <>
      <div className={classes.numberActionsDialogContent}>{renderDialogMessage()}</div>
      <div className={classes.numberActionsDialogActions}>{renderDialogActions()}</div>
    </>
  );

  return (
    <MessageDialog
      contentClass={classes.defaultElementWidth24}
      onCancel={onCancel}
      disableClose={loading}
      {...modalProps}
      title={getCurrentTitle()}
      renderContent={renderDialogContent()}
    />
  );
};

export default DialogPayForNumber;
